import React, { useCallback } from "react";
import {
  Box,
  AddOutlined,
  useState,
  useEffect,
} from "../../../../Collections/Imports";
import { useDispatch, useSelector } from "react-redux";
import { useApiCallMutation } from "../../../../Services/ApiCallService";
import AgGridComponent from "../../../../Components/CommonComponents/AG-Grid";
import AddEditPublisherPingtree from "../../../../Forms/AddEditPublisherPingtree";
import {
  getData,
  removeData,
} from "../../../../Store/PublisherModule/publisherPingtreeSetting";
import { publisherModuleApiEndPoints } from "../../../../Config/APIsEndpoints";
import CircularProgressBar from "../../../../Components/UIComponents/CircularProgressBar";
import { updateSnackBarConfig } from "../../../../Store/Global/SnackBar";
// const ShowCellRenderer = (params) => {
//   //debugger;
//   if (params.data.count === undefined) {
//     return null;
//   }

//   return (
//     <div>
//       <div className="show-name">{params.data[params.column.colId]}</div>
//       {/* <div className="show-presenter">{value.presenter}</div> */}
//     </div>
//   );
// };

class ShowCellRenderer {
  init(params) {
    const cellBlank = !params.value;
    if (params.data.count === undefined) {
      return null;
    }

    this.ui = document.createElement("div");
    this.ui.innerHTML =
      '<div class="show-name" style="border-top:1px solid #d2cfcf">' +
      params.data[params.column.colId] +
      "" +
      "</div>";
  }

  getGui() {
    return this.ui;
  }

  refresh() {
    return false;
  }
}

function PubPingtreeSetting() {
  const dispatch = useDispatch();
  const [isOpenAddEditBox, setIsOpenAddEditBox] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [rowSelected, setRowSelected] = useState({});
  const [rowData, setRowData] = useState("");
  const [itemPerIndex, setItemPerIndex] = useState(100);
  const [paginationIndex, setPaginationIndex] = useState(0);
  const perPageCountOptions = [
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "1000", value: 1000 },
  ];
  const [GetPubPingtree, getPubPingtreeData] = useApiCallMutation();
  const [DeletePubPingtree, DeletePubPingtreeData] = useApiCallMutation();
  let currentPubData = JSON.parse(
    sessionStorage.getItem("currentPublisherData")
  );
  const PingtreeSettingRowData = useSelector(
    (state) => state.publisherPingtreeSetting?.data?.pubPingtree
  );
  console.log(PingtreeSettingRowData);
  // Transform the array into the desired format
  const tableData = PingtreeSettingRowData?.pubPingTreeSetting?.flatMap(
    (item) => {
      const pingTrees = item.pingTrees || item.pingTrees || [];
      const count = pingTrees.length;

      return pingTrees.map((pingTree, index) => ({
        ptsUniqueId: item?.ptsUniqueId || item.ptsUniqueId || "",
        pingTreeType: item?.pingTreeType || item.pingTreeType || "",
        channelId: item?.channelId || item.channelId || "",
        pingTreeId: pingTree.pingTreeId,
        pingtreeName: pingTree.pingTreeName,
        value: pingTree.value,
        createdOn: item?.createdOn || item.createdOn || "",
        status: item?.status || item.status || "",
        count: index === 0 ? count : undefined, // Include count only for the first element
      }));
    }
  );

  const [columnDefs, setColumnDefs] = useState([
    {
      // headerName: "Type",
      field: "pingTreeType",
      // filter: true,
      cellRenderer: ShowCellRenderer,
      rowSpan: function (params) {
        return 3;
      },
      cellClassRules: {
        "show-cell": "value !== undefined",
      },
      cellDataType: false,
    },
    {
      headerName: "Channel",
      field: "channelId",
      filter: true,
      cellRenderer: ShowCellRenderer,
      rowSpan: function (params) {
        return 3;
      },
      cellClassRules: {
        "show-cell": "value !== undefined",
      },
    },
    {
      field: "pingtreeName",
      width: 200,
    },
    {
      headerName: "Distribution",
      field: "value",
      filter: true,
    },
  ]);

  // Handle Grid Button ACtion
  const handleActions = (previousData, action) => {
    switch (action) {
      case "EDIT":
        setRowSelected(previousData);
        setIsEditMode(true);
        setIsOpenAddEditBox(true);
        break;
      case "DELETE":
        DeletePubPingtree({
          endPoint: publisherModuleApiEndPoints.deleteTreeSetting,
          method: "POST",
          // data: { data: listId },

          data: {
            data: {
              ptsUniqueId: previousData.ptsUniqueId,
            },
          },
        });
        dispatch(removeData(previousData.ptsUniqueId));
        dispatch(
          updateSnackBarConfig({
            open: true,
            color: "success",
            message: "Data deleted successfully",
          })
        );
        break;
    }
  };

  const onGridReady = useCallback((params) => {
    setRowData(tableData);
  }, []);
  useEffect(() => {
    GetPubPingtree({
      endPoint: publisherModuleApiEndPoints.getTreeSetting,
      method: "POST",
      data: {
        data: {
          pubUniqueId: currentPubData.pubUniqueId,
        },
      },
    });
  }, [paginationIndex, itemPerIndex]);

  useEffect(() => {
    if (
      getPubPingtreeData?.isSuccess &&
      getPubPingtreeData?.data?.data?.pubPingTreeSetting?.length
    ) {
      dispatch(getData(getPubPingtreeData?.data?.data));
    }
  }, [getPubPingtreeData]);

  return (
    <>
      <Box
        sx={{
          padding: "22px",
        }}
      >
        <Box>
          {isOpenAddEditBox && (
            <AddEditPublisherPingtree
              defaultValues={rowSelected}
              isOpen={isOpenAddEditBox}
              setOpen={setIsOpenAddEditBox}
              isEditMode={isEditMode}
              setIsEditMode={setIsEditMode}
            />
          )}
        </Box>
        {/* Data Table Start */}
        {getPubPingtreeData.status === "isUninitialized" && (
          <CircularProgressBar />
        )}
        {console.log(tableData)}
        {getPubPingtreeData.status !== "isUninitialized" && (
          <AgGridComponent
            // suppressRowTransform={true}
            rowData={tableData || []}
            columnDefs={columnDefs || []}
            performAction={handleActions}
            // search={false}
            //columnDefs={[]}
            search={false}
            addActionIcon={<AddOutlined />}
            addAction={setIsOpenAddEditBox}
            AddButtonName={"Add New Distribution Setting"}
            downlodBtn={false}
            tableName={"Distribution By Publisher"}
            actions={["EDIT", "DELETE"]}
            domLayout="autoHeight"
            height={"100%"}
            rowMode
          />
        )}
      </Box>
    </>
  );
}

export default PubPingtreeSetting;
