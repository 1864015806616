import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};
const typeOptions = createSlice({
  name: "typeOptions",
  initialState,
  reducers: {
    updateTypeOptions: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { updateTypeOptions } = typeOptions.actions;
export default typeOptions.reducer;
