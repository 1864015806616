

/* The code defines several objects that store API endpoint configurations for different modules of an
application. */
const apiEndPointsConfig = {
  getClients: "/core/get-clients",
  getToken: "/core/get-token",
  introspect: "/secured/introspect",
  revokToken: "/secured/revoke-token",
  refreshToken: "/core/refresh-token",
};

const publisherModuleApiEndPoints = {
  getAllPubs: "/lds/publisher/list-all-publishers",
  createPub: "/lds/publisher/create-publisher",
  updatePub: "/lds/publisher/update-publisher",
  getPubById: "/lds//publisher/single-publisher",
  addRevShare: "/lds/publisher/add-publisher-rev-share",
  getRevShare: "/lds/publisher/get-revshare",
  addBucketBank: "/lds/publisher/add-pub-bucket-bank",
  updateBucketBank: "/lds/publisher/update-pub-bucket-bank",
  deleteBucketBank: "/lds/publisher/delete-pub-bucket-bank",
  getBucketBank: "/lds/publisher/get-pub-bucket-bank",
  createPubContactDetails: "/lds/publisher/create-pub-contact-details",
  getPubContactDetails: "/lds/publisher/get-pub-contact-details",
  deletePubContactDetails: "/lds/publisher/delete-pub-contact-details",
  updatePubContactDetails: "/lds/publisher/update-pub-contact-value",
  addPubTracking: "/lds/publisher/add-pub-tracking",
  getPubTracking: "/lds/publisher/get-pub-tracking",
  updatePubTracking: "/lds/publisher/update-pub-tracking",
  deletePubTracking: "/lds/publisher/delete-pub-tracking",
  addTreeSetting: "lds/publisher/add-pub-ping-tree-setting",
  getTreeSetting: "lds/publisher/get-pub-ping-tree-setting",
  deleteTreeSetting: "lds/publisher/delete-pub-ping-tree-setting",
};

const channelModuleApiEndPoints = {
  addChannel: "",
  updateChannel: "",
  deleteChannel: "",
};

const buyerModuleAPiENdPoints = {
  getAllbuyers: "/lds/buyer/get-all-buyers",
  createBuyer: "/lds/buyer/create-buyer",
  updateBuyer: "/lds/buyer/update-buyer",
  getBuyerById: "/lds/buyer/get-buyer-by-id",
  getStandardField: "/lds/buyer/get-standard-field",
};

const buyerLabelApiEndPoint = {
  getBuyerLabel: "/lds/buyer/get-buyer-label",
  updateBuyerLabel: "/lds/buyer/update-buyer-label",
  createBuyerLabel: "lds/buyer/add-buyer-label",
  deleteBuyerLabel: "lds/buyer/delete-buyer-label"
};

const campaignModuleAPiEndPoints = {
  getAllCampaigns: "/lds/buyer/get-all-campaign",
  createCampaign: "/lds/buyer/add-campaign",
  updateCampaign: "/lds/buyer/update-campaign",
  getCampaignById: "/lds/buyer/get-campaign-by-id",
  addCampaignFilter: "/lds/buyer/add-campaign-filter",
  getCampaignFilter: "/lds/buyer/get-campaign-filter",
  addCampaignDuplicate: "/lds/buyer/campaign-duplicates",
  getCampaignDuplicate: "/lds/buyer/get-campaign-duplicates",
  getCampaignPLDNPL: "/lds/buyer/get-campaign-pldnpl-by-campaign-id",
  addCampaignPLDNPL: "/lds/buyer/add-campaign-pl-dnpl",
  addCampaignSchedule: "/lds/buyer/add-campaign-schedule",
  getAllSchedule: "/lds/buyer/get-campaign-schedule",
  updateSchedule: "/lds/buyer/update-campaign-schedule",
  deleteCampaignSchedule: "/lds/buyer/delete-campaign-schedule",
  createCampaignIntegration: "/lds/buyer/add-campaign-integration-setting",
  getCampaignIntegration: "/lds/buyer/get-campaign-integration",
  addFilterByPub: "/lds/buyer/add-filter-by-pub",
  getFilterByPub: "/lds/buyer/get-filter-by-pub",
  deleteFilterByPub: "/lds/buyer/delete-filter-by-pub",
  updateFilterByPub: "/lds/buyer/update-filter-by-pub",
};

const coreAPIEndsPoints = {
  getLabel: "/lds/core/get-label",
  addLabel: "/lds/core/add-label",
  getManager: "/lds/core/get-manager",
  addManager: "/lds/core/add-manager",
  getType: "/lds/core/get-type",
  addType: "/lds/core/add-type",
  addMasterPlDnpl: "/lds/core/add-master-pl-dnpl",
  getAllMasterPlDnpl: "/lds/core/get-all-master-pl-dnpl",
  updateMasterPlDnpl: "/lds/core/update-master-pl-dnpl",
  getAllPLDNPL: "/lds/core/get-all-pl-dnpl",
  deleteMasterPlDnpl: "/lds/core/delete-master-pl-dnpl",

  addPlDnplMasterValues: "/lds/core/add-plDnpl-master-values",
  getPlDnplMasterValues: "/lds/core/get-plDnpl-master-values",
  AddAppendPlDnplMasterValues: "/lds/core/add-append-plDnpl-master-values",
};

const pingtreeApiEndPoints = {
  getPingtreeList: "/lds/tree/get-all-ping-tree",
};

const campaignTagsModuleAPiEndPoints = {
  getCampaignsTags: "/lds/buyer/get-campaignTags",
  updateCampaignTags: "/lds/buyer/update-campaignTags",
  deleteCampaignTags: "/lds/buyer/delete-campaignTags",
  addCampaignTags: "/lds/buyer/add-campaignTags",
};

const buyerTagModuleAPiEndPoints = {
  getBuyerTags: "/lds/buyer/get-buyer-tags",
  updateBuyerTag: "/lds/buyer/update-buyer-tags",
  deleteBuyerTags: "/lds/buyer/delete-buyer-tags",
  addBuyerTags: "/lds/buyer/add-buyer-tags",
};
export {
  buyerModuleAPiENdPoints,
  apiEndPointsConfig,
  coreAPIEndsPoints,
  campaignModuleAPiEndPoints,
  publisherModuleApiEndPoints,
  channelModuleApiEndPoints,
  pingtreeApiEndPoints,
  campaignTagsModuleAPiEndPoints,
  buyerTagModuleAPiEndPoints,
  buyerLabelApiEndPoint
};
