import React from "react";
import {
  useState,
  Drawer,
  Grid,
  Box,
  Typography,
  Divider,
  Stack,
  useEffect,
} from "../../Collections/Imports";
import GlobalInput from "../../Components/CommonComponents/GlobalInput";
import GlobalDropdown from "../../Components/CommonComponents/GlobalDropdown";
import CustomButton from "../../Components/CommonComponents/GlobalButton";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  pingtreeApiEndPoints,
  publisherModuleApiEndPoints,
} from "../../Config/APIsEndpoints";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import {
  addData,
  updateData,
} from "../../Store/PublisherModule/publisherPingtreeSetting";
import { useDispatch } from "react-redux";
import { updateSnackBarConfig } from "../../Store/Global/SnackBar";

function AddEditPublisherPingtree(props) {
  const dispatch = useDispatch();
  const { setOpen, isOpen, defaultValues, isEditMode, setIsEditMode } = props;
  const [pingtreeOptions, setPingtreeOptions] = useState([]);
  const [selectedType, setSelectedType] = useState();
  let currentPubData = JSON.parse(
    sessionStorage.getItem("currentPublisherData")
  );
  const [AddTreeSetting, AddTreeSettingData] = useApiCallMutation();
  const treeOption = useGetApiCallQuery({
    endPoint: pingtreeApiEndPoints.getPingtreeList,
    method: "GET",
  });

  const filteredPingtrees = treeOption?.data?.data?.pingTree.filter(
    (pingtree) => pingtree.treeType === selectedType
  );

  // Set Initial Value here
  const initialValues = {
    channelId: "",
    pingTreeType: "",
    pingTrees: [],
  };
  // Set Validation Schema here
  const validationSchema = Yup.object().shape({
    pingTreeType: Yup.string().required("First Name is required"),
  });

  // initialise Form here
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const SetFormValues = (data) => {
    setValue("channelId", data?.channelId, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("pingTreeType", data?.pingTreeType, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
  };
  const onFormSubmit = (data) => {
    // Get the values of all form fields
    const formValues = data;

    // Extract the dynamically generated pingtree values
    const pingtreeValues = Object.keys(formValues)
      .filter((key) => key.startsWith("pingtree-"))
      .map((key) => ({
        pingTreeId: parseInt(key.replace("pingtree-", "")),
        value: parseInt(formValues[key]),
      }));

    // Combine the pingtree values with the rest of the form data
    const finalData = {
      ...data,
      pubUniqueId: currentPubData.pubUniqueId,
      pingTrees: pingtreeValues,
    };

    if (!isEditMode) {
      AddTreeSetting(
        {
          endPoint: publisherModuleApiEndPoints.addTreeSetting,
          method: "POST",
          data: { data: finalData },
        },
        reset()
      );
    }
    // Now you can submit the data to your backend or perform further actions
  };

  const handleSetButton = (e) => {
    setValue(e, "100");
  };
  useEffect(() => {
    const type = watch("pingTreeType");
    setSelectedType(type);
  }, [watch("pingTreeType")]);

  useEffect(() => {
    setPingtreeOptions((prevOptions) => {
      const newOptions = filteredPingtrees?.map((pingtree) => ({
        name: pingtree.name,
        id: pingtree.treeUniqueId,
      }));

      // Check if options have changed before updating the state
      if (JSON.stringify(newOptions) !== JSON.stringify(prevOptions)) {
        if (!isEditMode) {
          const resetValues = {};
          newOptions?.forEach((pingtree) => {
            resetValues[`pingtree-${pingtree.id}`] = 0;
          });

          reset(resetValues);
        }
        return newOptions;
      }

      return prevOptions;
    });
  }, [selectedType, filteredPingtrees, reset]);
  //Set Incoming Default values
  useEffect(() => {
    if (Object.keys(defaultValues).length && isEditMode) {
      SetFormValues(defaultValues);
    }
  }, []);

  useEffect(() => {
    if (AddTreeSettingData?.isSuccess && !AddTreeSettingData?.data?.error) {
      dispatch(addData(AddTreeSettingData?.data?.data));
      dispatch(
        updateSnackBarConfig({
          open: true,
          color: "success",
          message: "Data Added Successfully",
        })
      );
      setOpen(false);
      setIsEditMode(false);
    }
  }, [AddTreeSettingData]);
  return (
    <>
      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={() => {
          setOpen(false);
          setIsEditMode(false);
        }}
        sx={{
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            top: "15px",
            borderRadius: "16px",
            width: "100%",
            maxWidth: "500px",
          },
        }}
      >
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent={"space-between"}
          >
            <Box marginBottom={"15px"}>
              <Typography component="h6" variant="h6" fontWeight={600}>
                {(isEditMode && "Update Rule") || "Add Rule"}
              </Typography>
            </Box>
            <Box marginBottom={"15px"}>
              <Typography component="h7" variant="h7" fontWeight={200}>
                Clear All
              </Typography>
            </Box>
          </Stack>
          <Divider
            sx={{
              mb: "20px",
            }}
          />
          <Box component={"form"}>
            <Grid container spacing={4}>
              <Grid item xs={12} sx={{ mt: "10px" }}>
                <GlobalDropdown
                  control={control}
                  size="small"
                  options={[]}
                  name="channelId"
                  label="Channels"
                  error={!!errors.activeOn}
                  helperText={errors.activeOn?.message}
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: "10px" }}>
                <GlobalDropdown
                  control={control}
                  size="small"
                  options={[
                    { label: "Redirect", value: "Redirect" },
                    { label: "Silent", value: "Silent" },
                  ]}
                  name="pingTreeType"
                  label="Type"
                  error={!!errors.activeOn}
                  helperText={errors.activeOn?.message}
                />
              </Grid>
            </Grid>
            <Box sx={{ mt: "10px" }}>
              {pingtreeOptions?.map((label, index) => (
                <Grid
                  container
                  spacing={4}
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Grid item xs={3}>
                    {label.name}
                  </Grid>
                  <Grid item xs={5} sx={{ mt: "5px" }}>
                    <div key={index}>
                      <GlobalInput
                        control={control}
                        size="small"
                        id={`pingtree-${label.id}`}
                        name={`pingtree-${label.id}`}
                        type="number"
                        maxLength="3"
                        min="0"
                      />
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <CustomButton
                      labelColor="#7a7870"
                      variant="outlined"
                      color="white"
                      label="Set to 100"
                      sx={{ fontSize: "12px" }}
                      onClick={() => handleSetButton(`pingtree-${label.id}`)}
                      value={`pingtree-${label.id}`}
                    />
                  </Grid>
                </Grid>
              ))}
            </Box>
            <Box
              sx={{
                width: "100%",
                borderTop: "1px solid #DCE0E6",
                marginTop: "10px",
              }}
            >
              <Stack
                direction="row"
                component={Box}
                useFlexGap
                flexWrap="wrap"
                justifyContent={"flex-end"}
                bottom={0}
                marginTop={"1rem"}
              >
                <Box marginBottom={"15px"} marginRight={"5px"} float={"right"}>
                  <CustomButton
                    color="#3a5396"
                    label="Save"
                    onClick={handleSubmit(onFormSubmit)}
                  />
                </Box>
              </Stack>
            </Box>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

export default AddEditPublisherPingtree;
