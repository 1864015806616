import CallbackHandlerPage from "../Views/Authentication/CallbackHandler";
import Login from "../Views/Authentication/Login";
import Layout from "../Layout";
import AuthenticationRoutes from "./AuthenticationRoutes";
import Dashboard from "../Views/Dashboard";
import BuyerListingPage from "../Views/BuyerListing";
import { Navigate } from "react-router-dom";
import Logout from "../Views/Authentication/Logout";
import CampaignListingPage from "../Views/CampaignListing";
import CampaignConfiguration from "../Views/CampaignListing/CampaignConfiguration";
import PublisherListing from "../Views/PublisherListing";
import PublisherConfiguration from "../Views/PublisherListing/PublisherConfiguration";
import ChannelListing from "../Views/ChannelListing";
import PlAndDnpl from "../Views/PlAndDnpl";
import CampaignTagsPage from "../Views/CampaignTags";
import BuyersTagPage from "../Views/BuyersTag";
import BuyerLabel from "../Views/BuyerLabel";
const PublicRoutes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: "/dashboard",
        element: (
          <AuthenticationRoutes>
            <Dashboard />
          </AuthenticationRoutes>
        ),
      },
      {
        path: "/buyers",
        element: (
          <AuthenticationRoutes>
            <BuyerListingPage />,
          </AuthenticationRoutes>
        ),
      },
      {
        path: "/buyer-labels",
        element: (
          <AuthenticationRoutes>
            <BuyerLabel />,
          </AuthenticationRoutes>
        ),
      },
      {
        path: "/campaigns",
        element: (
          <AuthenticationRoutes>
            <CampaignListingPage />
          </AuthenticationRoutes>
        ),
        children: [{ path: ":id", element: <CampaignConfiguration /> }],
      },
      {
        path: "/publisher",
        element: (
          <AuthenticationRoutes>
            <PublisherListing />
          </AuthenticationRoutes>
        ),
        children: [{ path: ":id", element: <PublisherConfiguration /> }],
      },
      {
        path: "/channel/",
        element: (
          <AuthenticationRoutes>
            <ChannelListing />{" "}
          </AuthenticationRoutes>
        ),
      },
      {
        path: "/pldnpl",
        element: (
          <AuthenticationRoutes>
            <PlAndDnpl />
          </AuthenticationRoutes>
        ),
        children: [{ path: ":id", element: <PublisherConfiguration /> }],
      },
      {
        path: "/campaign-tags",
        element: (
          <AuthenticationRoutes>
            <CampaignTagsPage />
          </AuthenticationRoutes>
        ),
        children: [{ path: ":id", element: <PublisherConfiguration /> }],
      },
      {
        path: "/buyer-tags",
        element: (
          <AuthenticationRoutes>
            <BuyersTagPage />
          </AuthenticationRoutes>
        ),
        children: [{ path: ":id", element: <PublisherConfiguration /> }],
      },
    ],
  },

  { path: "/login", element: <Login /> },
  { path: "/callback", element: <CallbackHandlerPage /> },
  { path: "/logout", element: <Logout /> },
];

export default PublicRoutes;
