import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  data: {
    pubPingtree: [],
  },
};

const PubPingtreeData = createSlice({
  name: "pubPingtreeData",
  initialState,
  reducers: {
    getData: (state, action) => {
      state.data.pubPingtree = action.payload;
    },
    addData: (state, action) => {
      state.data.pubPingtree.pubPingTreeSetting.unshift(action.payload);
    },
    updateData: (state, action) => {
      const updatedRow = action.payload;
      const index = state.data.pubPingtree.pubPingTreeSetting.findIndex(
        (data) => data?.ptsUniqueId === updatedRow?.ptsUniqueId
      );
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.data.pubPingtree.pubPingTreeSetting[index][key] = updatedRow[key];
      });
    },
    removeData: (state, action) => {
        state.data.pubPingtree.pubPingTreeSetting = state.data.pubPingtree.pubPingTreeSetting.filter(
          (data) => data?.ptsUniqueId !== action.payload
        );
    },
  },
});

export const { getData, addData, updateData, removeData } = PubPingtreeData.actions;
export default PubPingtreeData.reducer;
