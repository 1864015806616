import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  data: {
    channelData: [],
  },
};

const ChannelData = createSlice({
  name: "channelData",
  initialState,
  reducers: {
    getData: (state, action) => {
      state.data.channelData = action.payload;
    },
    addData: (state, action) => {
      state.data.channelData.unshift(action.payload);
    },
    updateData: (state, action) => {
      const updatedRow = action.payload;
      const index = state.data.channelData.findIndex(
        (data) => data?.channelId === updatedRow?.channelId
      );
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.data.channelData[index][key] = updatedRow[key];
      });
    },
    removeData: (state, action) => {
      state.data.channelData = state.data.channelData.filter(
        (data) => data?.channelId !== action.payload
      );
    },
  },
});

export const { getData,addData,updateData,removeData } = ChannelData.actions;
export default ChannelData.reducer;
