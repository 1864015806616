import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ExpandMore,
  moment,
} from "../../Collections/Imports";
import GlobalDateRangePicker from "../../Components/CommonComponents/GlobalDateRangePicker";
import "./style.css";
import GlobalMultiSelect from "../../Components/CommonComponents/GlobalMultiSelect";
import { useDispatch, useSelector } from "react-redux";
import { updateCampaignFilterData } from "../../Store/CampaignModule/filterSession";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import {
  buyerModuleAPiENdPoints,
  coreAPIEndsPoints,
} from "../../Config/APIsEndpoints";
import { updateLabelOptions } from "../../Store/Global/LabelOptions";
import { updateManagerOptions } from "../../Store/Global/ManagerOptions";
import { updateTypeOptions } from "../../Store/Global/TypeOptions";
import { updateBuyesOptions } from "../../Store/Global/BuyersOptions";
import { click } from "@testing-library/user-event/dist/click";
import CustomButton from "../../Components/CommonComponents/GlobalButton";

const CampaignFilters = (props) => {
  const { setOpen, isOpen, defaultValues, isEditMode, defaultCampaign } = props;
  const [labelDropdown, setLabelDropdown] = useState([]);
  const [typeDropdown, setTypeDropdown] = useState([]);
  const previousFilterData = useSelector(
    (state) => state?.campaignDataFilterSession?.filterData
  );

  console.log(">>>>>>>>>>>>>>>>>>>>>>>", defaultCampaign);

  const AllFilterLength = useSelector(
    (state) => state?.campaignDataFilterSession?.filterData
  );

  const buyersOptions = useSelector((state) => state?.buyersOptions?.data);
  const dispatch = useDispatch();

  const getLabelOptions = useGetApiCallQuery({
    endPoint: coreAPIEndsPoints.getLabel,
    method: "GET",
  });
  const getManagerOptions = useGetApiCallQuery({
    endPoint: coreAPIEndsPoints.getManager,
    method: "GET",
  });
  const getTypeOptions = useGetApiCallQuery({
    endPoint: coreAPIEndsPoints.getType,
    method: "GET",
  });
  const [GetAllbuyers, AllbuyersData] = useApiCallMutation();

  if (getManagerOptions?.isSuccess) {
    dispatch(updateManagerOptions(getManagerOptions?.data?.data));
  }

  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 2);
  useEffect(() => {
    GetAllbuyers({
      endPoint: buyerModuleAPiENdPoints.getAllbuyers,
      method: "POST",
      data: {
        data: {
          index: "all",
          itemPerIndex: "all",
          buyerNames: [],
          labelIds: [],
          typeIds: [],
          fromDate: currentDate,
          toDate: new Date(),
        },
      },
    });
  }, []);

  useEffect(() => {
    if (AllbuyersData?.isSuccess && AllbuyersData?.data?.data?.buyers?.length) {
      const buyersOptions = AllbuyersData?.data?.data?.buyers.map((buyer) => {
        return { label: buyer?.buyerName, value: buyer?.buyerName };
      });
      dispatch(updateBuyesOptions(buyersOptions));
    }
  }, [AllbuyersData]);
  useEffect(() => {
    if (getLabelOptions.isSuccess) {
      setLabelDropdown(getLabelOptions?.data?.data?.label);
      dispatch(updateLabelOptions(getLabelOptions?.data?.data));
    }
  }, [getLabelOptions]);

  useEffect(() => {
    if (getTypeOptions.isSuccess) {
      setTypeDropdown(getTypeOptions?.data?.data?.type);
      dispatch(updateTypeOptions(getTypeOptions?.data?.data));
    }
  }, [getTypeOptions]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      status: [],
      buyerName: [],
      label: [],
      type: [],
    },
    // resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const statusOptions = [
    { label: "Active", value: "true" },
    { label: "Disabled", value: "false" },
  ];

  //***********FORM SUBMISSION ********//
  const onFormSubmit = (data) => {
    const filterData = {
      status: data?.status,
      buyerName: data?.buyerName,
      label: data?.label,
      type: data?.type,
      fromDate: data?.dateRange
        ? moment(data?.dateRange[0]?.startDate).startOf("day").toDate()
        : "",
      toDate: data?.dateRange
        ? moment(data?.dateRange[0]?.endDate).endOf("day").toDate()
        : "",
    };
    dispatch(updateCampaignFilterData(filterData));
    setOpen(false);
  };

  // Call setFormValue after initialization
  useEffect(() => {
    if (previousFilterData && Object.keys(previousFilterData)?.length) {
      setFormValue(previousFilterData);
    }
  }, [previousFilterData]);

  // Set Form Value
  const setFormValue = (data) => {
    const dateRange = {
      startDate: new Date(data?.fromDate),
      endDate: data?.toDate,
      key: "selection",
    };

    setValue("status", data?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("buyerName", data?.buyerName, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("label", data?.label, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("type", data?.type, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("dateRange", dateRange, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  return (
    <>
      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={() => setOpen(false)}
        sx={{
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            top: "15px",
            borderRadius: "16px",
            maxWidth: "500px",
            width: "100%",
          },
        }}
      >
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent={"space-between"}
          >
            <Box marginBottom={"15px"}>
              <Typography component="h6" variant="h6" fontWeight={600}>
                Filters
              </Typography>
            </Box>
            <Box marginBottom={"15px"}>
              <Typography
                component="h7"
                variant="h7"
                fontWeight={200}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  dispatch(updateCampaignFilterData({}));
                  reset();
                }}
              >
                Clear All
              </Typography>
            </Box>
          </Stack>

          <Divider
            sx={{
              mb: "20px",
            }}
          />

          <Box component={"form"} className="accordionGrid">
            <Accordion
              className="accordionWrapper"
              defaultExpanded={
                defaultCampaign == "status"
                  ? true
                  : defaultCampaign == "all"
                  ? true
                  : false
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                className="accordionSummary"
              >
                <Typography className="filterTitle">
                  Status{" "}
                  {AllFilterLength.status &&
                    AllFilterLength.status.length > 0 && (
                      <span className="count-style">
                        <p>{AllFilterLength.status.length}</p>
                      </span>
                    )}
                </Typography>
              </AccordionSummary>

              <AccordionDetails sx={{ backgroundColor: "#e0e0e05e" }}>
                {/* <GlobalInput control={control} name="status" label="" /> */}
                <GlobalMultiSelect
                  control={control}
                  size="small"
                  name="status"
                  label=""
                  options={statusOptions}
                  required
                  error={!!errors.status}
                  helperText={errors.status?.message}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="accordionWrapper"
              defaultExpanded={
                defaultCampaign == "name"
                  ? true
                  : defaultCampaign == "all"
                  ? true
                  : false
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                className="accordionSummary"
              >
                <Typography className="filterTitle">
                  Buyer{" "}
                  {AllFilterLength.buyerName &&
                    AllFilterLength.buyerName.length > 0 && (
                      <span className="count-style">
                        <p>{AllFilterLength.buyerName.length}</p>
                      </span>
                    )}
                </Typography>
              </AccordionSummary>

              <AccordionDetails sx={{ backgroundColor: "#e0e0e05e" }}>
                {/* <GlobalInput control={control} name="buyerName" label="" /> */}
                <GlobalMultiSelect
                  control={control}
                  size="small"
                  name="buyerName"
                  label=""
                  options={buyersOptions || []}
                  required
                  error={!!errors.buyerName}
                  helperText={errors.buyerName?.message}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="accordionWrapper"
              defaultExpanded={
                defaultCampaign == "label"
                  ? true
                  : defaultCampaign == "all"
                  ? true
                  : false
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                className="accordionSummary"
              >
                <Typography className="filterTitle">
                  Label{" "}
                  {AllFilterLength.label &&
                    AllFilterLength.label.length > 0 && (
                      <span className="count-style">
                        <p>{AllFilterLength.label.length}</p>
                      </span>
                    )}
                </Typography>
              </AccordionSummary>

              <AccordionDetails sx={{ backgroundColor: "#e0e0e05e" }}>
                {/* <GlobalDropdown control={control} name="label" options={[]} /> */}
                <GlobalMultiSelect
                  control={control}
                  size="small"
                  name="label"
                  label=""
                  options={labelDropdown || []}
                  required
                  error={!!errors.label}
                  helperText={errors.label?.message}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="accordionWrapper"
              defaultExpanded={
                defaultCampaign == "type"
                  ? true
                  : defaultCampaign == "all"
                  ? true
                  : false
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                className="accordionSummary"
              >
                <Typography className="filterTitle">
                  Type{" "}
                  {AllFilterLength.type && AllFilterLength.type.length > 0 && (
                    <span className="count-style">
                      <p>{AllFilterLength.type.length}</p>
                    </span>
                  )}
                </Typography>
              </AccordionSummary>

              <AccordionDetails sx={{ backgroundColor: "#e0e0e05e" }}>
                {/* <GlobalDropdown control={control} name="type" options={[]} /> */}
                <GlobalMultiSelect
                  control={control}
                  size="small"
                  name="type"
                  label=""
                  options={typeDropdown || []}
                  required
                  error={!!errors.type}
                  helperText={errors.type?.message}
                />
              </AccordionDetails>
            </Accordion>
            {/* </Grid> */}

            {/* <Grid item xs={12}> */}
            <Accordion
              className="accordionWrapper"
              defaultExpanded={
                defaultCampaign == "created"
                  ? true
                  : defaultCampaign == "all"
                  ? true
                  : false
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                className="accordionSummary"
              >
                <Typography className="filterTitle">Create Date</Typography>
              </AccordionSummary>

              <AccordionDetails sx={{ backgroundColor: "#e0e0e05e" }}>
                <Controller
                  name="dateRange"
                  control={control}
                  render={({ field }) => (
                    <GlobalDateRangePicker
                      defaultRange={{
                        startDate: new Date(
                          new Date().setDate(new Date().getDate() - 60)
                        ),
                        endDate: new Date(),
                        key: "selection",
                      }}
                      {...field}
                    />
                  )}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Stack
            direction="row"
            component={Box}
            useFlexGap
            flexWrap="wrap"
            justifyContent={"flex-end"}
            bottom={0}
            marginTop={"1rem"}
          >
            <Box marginBottom={"15px"} marginRight={"5px"}>
              <CustomButton
                color="#3a5396"
                // label="Add Campaign"
                label="Apply Filter"
                onClick={handleSubmit(onFormSubmit)}
              />
            </Box>
            <Box marginBottom={"15px"}>
              <CustomButton
                labelColor="#7a7870"
                variant="outlined"
                color="white"
                label="Close"
                onClick={() => setOpen(false)}
              />
            </Box>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default CampaignFilters;
