import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // filterData: {
  //   fromDate:"2020-06-30T18:30:00.000Z",
  // },
  pubOptionsList: {},
};

const pubOptionsList = createSlice({
  name: "pubOptionsList",
  initialState,
  reducers: {
    updatePubOptionsListData: (state, action) => {
      state.pubOptionsList = action.payload;
    },
  },
});

export const { updatePubOptionsListData } = pubOptionsList.actions;
export default pubOptionsList.reducer;
