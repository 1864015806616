import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  filterData: {
    fromDate:"2020-06-30T18:30:00.000Z",
  },
};

const buyersDataFilterSession = createSlice({
  name: "buyersDataFilterSession",
  initialState,
  reducers: {
    updateBuyersFilterData: (state, action) => {
      state.filterData = action.payload;
    },
  },
});

export const { updateBuyersFilterData } = buyersDataFilterSession.actions;
export default buyersDataFilterSession.reducer;
