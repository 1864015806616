import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Box,
  Card,
  Divider,
  Drawer,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import GlobalInput from "../../Components/CommonComponents/GlobalInput";
import GlobalDropdown from "../../Components/CommonComponents/GlobalDropdown";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  buyerModuleAPiENdPoints,
  coreAPIEndsPoints,
} from "../../Config/APIsEndpoints";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import { useDispatch } from "react-redux";
import {
  addSingleBuyerData,
  updateSingleBuyerData,
} from "../../Store/BuyerModule";
import GlobalMultiSelect from "../../Components/CommonComponents/GlobalMultiSelect";
import CustomButton from "../../Components/CommonComponents/GlobalButton";
import { updateLabelOptions } from "../../Store/Global/LabelOptions";
import { updateManagerOptions } from "../../Store/Global/ManagerOptions";
import { updateTypeOptions } from "../../Store/Global/TypeOptions";

const AddEditBuyer = (props) => {
  const { setOpen, isOpen, defaultValues, isEditMode, setIsEditMode } = props;
  const [defaultValuesSchema, setDefaultValuesSchema] = useState({});
  const dispatch = useDispatch();
  const [labelDropdown, setLabelDropdown] = useState([]);
  const [typeDropdown, setTypeDropdown] = useState([]);
  const [managerDropdown, setManagerDropdown] = useState([]);

  // Api Call Intialization
  const [CreatBuyer, creatBuyerData] = useApiCallMutation();
  const [UpdateBuyer, UpdateBuyerData] = useApiCallMutation();

  const getLabelOptions = useGetApiCallQuery({
    endPoint: coreAPIEndsPoints.getLabel,
    method: "GET",
  });
  const getManagerOptions = useGetApiCallQuery({
    endPoint: coreAPIEndsPoints.getManager,
    method: "GET",
  });
  const getTypeOptions = useGetApiCallQuery({
    endPoint: coreAPIEndsPoints.getType,
    method: "GET",
  });

  // if (getManagerOptions?.isSuccess) {
  //   dispatch(updateManagerOptions(getManagerOptions?.data?.data));
  // }

  //Set Incoming Default values
  useEffect(() => {
    if (Object.keys(defaultValues).length && isEditMode) {
      SetFormValues(defaultValues);
    }
  }, []);

  // const { data: managerData } = useGetApiCallQuery({
  //   endPoint: buyerModuleAPiENdPoints.getManager,
  //   method: "GET",
  // });

  // const { data: typeData } = useGetApiCallQuery({
  //   endPoint: buyerModuleAPiENdPoints.getType,
  //   method: "GET",
  // });

  // Update Created Buyer
  useEffect(() => {
    if (creatBuyerData?.isSuccess && !creatBuyerData?.data?.error) {
      dispatch(addSingleBuyerData(creatBuyerData.data?.data));
      setOpen(false);
      setIsEditMode(false);
    }
  }, [creatBuyerData]);
  useEffect(() => {
    if (UpdateBuyerData?.isSuccess && !UpdateBuyerData?.data?.error) {
      console.log("All Buyer DATA", UpdateBuyerData.data?.data);
      dispatch(updateSingleBuyerData(UpdateBuyerData.data?.data));
      setOpen(false);
      setIsEditMode(false);
    }
  }, [UpdateBuyerData]);

  useEffect(() => {
    if (getLabelOptions.isSuccess) {
      setLabelDropdown(getLabelOptions?.data?.data?.label);
      dispatch(updateLabelOptions(getLabelOptions?.data?.data));
    }
  }, [getLabelOptions]);

  useEffect(() => {
    if (getTypeOptions.isSuccess) {
      setTypeDropdown(getTypeOptions?.data?.data?.type);
      dispatch(updateTypeOptions(getTypeOptions?.data?.data));
    }
  }, [getTypeOptions]);

  useEffect(() => {
    if (getManagerOptions.isSuccess) {
      setManagerDropdown(getManagerOptions?.data?.data?.manager);
      dispatch(updateManagerOptions(getManagerOptions?.data?.data));
    }
  }, [getManagerOptions]);

  // Set Initial Value here
  const initialValues = {
    buyerName: "",
    managerId: "",
    labelId: "",
    buyerEmail: "",

    typeId: "",
    buyerAddress: "",
    buyerIntegrations: [],
    status: "",
  };

  // Set Validation Schema here
  const validationSchema = Yup.object().shape({
    buyerName: Yup.string()
      .matches(/[A-Za-z]/, "numbers are not allowed")
      .trim("Name cannot include leading and trailing spaces")
      .max(30)
      .required("This Field is required"),
    status: Yup.boolean().required("This Field is required"),
    buyerEmail: Yup.string().max(80).required("This Field is required"),
    buyerIntegrations: Yup.array().min(1, "This Field is required"),
  });

  // initialise Form here
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  // Set Forms Value

  const SetFormValues = (data) => {
    setValue("buyerName", data?.buyerName, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("managerId", data?.managerId, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue(
      "labelId",
      data?.labelId,

      {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      }
    );
    setValue("buyerEmail", data?.buyerEmail, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("typeId", data.typeId, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("buyerAddress", data?.buyerAddress, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("buyerIntegrations", data?.buyerIntegrations, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("status", data?.status == true ? "true" : "false");
  };

  //***********FORM SUBMISSION ********//
  const onFormSubmit = (data) => {
    if (isEditMode) {
      const modifiedData = data;
      modifiedData.id = defaultValues?.id;

      UpdateBuyer({
        endPoint: buyerModuleAPiENdPoints.updateBuyer,
        method: "POST",
        data: { data },
      });
    } else {
      CreatBuyer({
        endPoint: buyerModuleAPiENdPoints.createBuyer,
        method: "POST",
        data: { data },
      });
    }
  };
  const integrations = [
    { label: "Redirect", id: 1, value: "1" },
    { label: "Integration 1", id: 2, value: "2" },
  ];

  const buyerStatusOptions = [
    { label: "Active", id: "status-1", value: "true" },
    { label: "Disable", id: "status-2", value: "false" },
  ];
  return (
    <>
      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={() => {
          setOpen(false);
          setIsEditMode(false);
        }}
        sx={{
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            top: "15px",
            borderRadius: "16px",
            width: "100%",
            maxWidth: "500px",
          },
        }}
      >
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent={"space-between"}
          >
            <Box marginBottom={"15px"}>
              <Typography component="h6" variant="h6" fontWeight={600}>
                {(isEditMode && "Update Buyer") || "Add Buyer"}
              </Typography>
            </Box>
            <Box marginBottom={"15px"} onClick={() => reset()}>
              <Typography component="h7" variant="h7" fontWeight={200}>
                Clear All
              </Typography>
            </Box>
          </Stack>

          <Divider
            sx={{
              mb: "20px",
            }}
          />

          <Box component={"form"}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <GlobalInput
                  control={control}
                  size="small"
                  name="buyerName"
                  label="Buyer Name"
                  error={!!errors.buyerName}
                  helperText={errors.buyerName?.message}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  size="small"
                  name="managerId"
                  label="Manager"
                  options={managerDropdown || []}
                  error={!!errors.managerId}
                  helperText={errors.managerId?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  size="small"
                  name="typeId"
                  label="Type"
                  options={typeDropdown || []}
                  required
                  error={!!errors.typeId}
                  helperText={errors.typeId?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  size="small"
                  name="labelId"
                  label="Label"
                  options={labelDropdown || []}
                  error={!!errors.labelId}
                  helperText={errors.labelId?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalInput
                  control={control}
                  size="small"
                  name="buyerEmail"
                  label="Email"
                  error={!!errors.buyerEmail}
                  helperText={errors.buyerEmail?.message}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalMultiSelect
                  control={control}
                  size="small"
                  name="buyerIntegrations"
                  label="Integration"
                  options={integrations}
                  required
                  error={!!errors.buyerIntegrations}
                  helperText={errors.buyerIntegrations?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  size="small"
                  name="status"
                  label="Buyer Status"
                  options={buyerStatusOptions}
                  error={!!errors.status}
                  helperText={errors.status?.message}
                  required
                />
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            // pl: "1rem",
          }}
        >
          <Stack
            direction="row"
            component={Box}
            useFlexGap
            flexWrap="wrap"
            justifyContent={"flex-end"}
            bottom={0}
            marginTop={"1rem"}
          >
            <Box marginBottom={"15px"} marginRight={"5px"}>
              <CustomButton
                color="#3a5396"
                // label="Add Campaign"
                label={isEditMode ? "Update" : "Add"}
                onClick={handleSubmit(onFormSubmit)}
              />
            </Box>
            <Box marginBottom={"15px"}>
              <CustomButton
                labelColor="#7a7870"
                variant="outlined"
                color="white"
                label="Close"
                onClick={() => {
                  setOpen(false);
                  setIsEditMode(false);
                }}
              />
            </Box>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default AddEditBuyer;
