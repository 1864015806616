import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  tableData: {
    data: [],
    headers: [],
  },
};

const CampaignData = createSlice({
  name: "campaignsData",
  initialState,
  reducers: {
    updateCampaignsData: (state, action) => {
      state.tableData = action.payload;
    },
    updateSingleCampaignData: (state, action) => {
      const updatedRow = action.payload;
      const index = state.tableData.data.findIndex(
        (data) => data?.uniqueId === updatedRow?.uniqueId
      );
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.tableData.data[index][key] = updatedRow[key];
      });
    },
    addSingleCampaignData: (state, action) => {
      state.tableData.data.unshift(action.payload);
    },
    removeSingleCampaignData: (state, action) => {
      state.tableData = state.tableData.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});

export const {
  updateCampaignsData,
  updateSingleCampaignData,
  addSingleCampaignData,
  removeSingleCampaignData,
} = CampaignData.actions;
export default CampaignData.reducer;
