import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ExpandMore,
  moment,
} from "../../Collections/Imports";
import GlobalDateRangePicker from "../../Components/CommonComponents/GlobalDateRangePicker";
import "./style.css";
import GlobalMultiSelect from "../../Components/CommonComponents/GlobalMultiSelect";
import { useDispatch, useSelector } from "react-redux";
import { updatePublisherFilterData } from "../../Store/PublisherModule/filter";
import { updatePubOptions } from "../../Store/PublisherModule/filter";

import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import {
  publisherModuleApiEndPoints,
  coreAPIEndsPoints,
} from "../../Config/APIsEndpoints";
import { updateLabelOptions } from "../../Store/Global/LabelOptions";
import { updateManagerOptions } from "../../Store/Global/ManagerOptions";
import { updateTypeOptions } from "../../Store/Global/TypeOptions";
import { updateBuyesOptions } from "../../Store/Global/BuyersOptions";
import { click } from "@testing-library/user-event/dist/click";
import CustomButton from "../../Components/CommonComponents/GlobalButton";
import { allPubData } from "../../Store/PublisherModule";
import GlobalDropdown from "../../Components/CommonComponents/GlobalDropdown";
import { updatePubOptionsListData } from "../../Store/PublisherModule/PubOptions";

const PublisherFilters = (props) => {
  const {
    setOpen,
    isOpen,
    defaultValues,
    isEditMode,
    defaultCampaign,
    ClearFilters,
  } = props;

  const previousFilterData = useSelector(
    (state) => state?.publisherFilterSession?.filterData
  );
  const dispatch = useDispatch();
  const [GetPublisherList, PublisherList] = useApiCallMutation();
  const [GetPublisherListTable, PublisherListTableData] = useApiCallMutation();

  useEffect(() => {
    GetPublisherListTable({
      endPoint: publisherModuleApiEndPoints.getAllPubs,
      method: "POST",
      data: {
        data: {
          index: "0",
          itemPerIndex: "500",
          publisherNames: "",
          emails: "all",
          status: "all",
          fromDate: currentDate,
          toDate: new Date(),
        },
      },
    });
  }, []);

  useEffect(() => {
    if (
      PublisherListTableData?.isSuccess &&
      PublisherListTableData?.data?.data?.publishers
    ) {
      dispatch(updatePubOptionsListData(PublisherListTableData?.data?.data));
    }
  }, [PublisherListTableData]);

  const AllFilterLength = useSelector(
    (state) => state?.publishersDataFilterSession?.filterData
  );

  console.log("AllFilterLength", AllFilterLength);

  let pubList = useSelector(
    (state) => state?.publisherData?.data?.allPubData.publishers
  );
  const allPubList = useSelector(
    (state) => state?.publisherFilterSession?.pubOptions
  );

  if (allPubList?.length > 0) {
    pubList = allPubList;
  }
  const pubOptions = pubList.map((publisher) => {
    return { label: publisher?.label, value: publisher?.label };
  });

  const currentDate = new Date("July 01, 2020");

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      status: "All",
      name: ["All"],
      label: ["All"],
      type: ["All"],
      labelIds: ["All"],
      publisher: ["All"],
    },
    // resolver: yupResolver(validationSchema),
    mode: "all",
  });

  const [selectedDateFilter, setSelectedDateFilter] = useState({
    startDate: new Date("July 01, 2020"),
    endDate: new Date(),
    key: "selection",
  });

  console.log("--------------------------------", previousFilterData);

  //***********FORM SUBMISSION ********//
  const onFormSubmit = (data) => {
    console.log("Filter Data-----------", data);
    const filterData = {
      status: data?.status,
      publisher: data?.publisher,
      label: data?.label,
      labelIds: ["all"],
      fromDate: data?.dateRange
        ? moment(selectedDateFilter?.startDate).startOf("day").toDate()
        : moment(data?.dateRange[0]?.startDate).startOf("day").toDate(),
      toDate: data?.dateRange
        ? moment(data?.dateRange[0]?.endDate).endOf("day").toDate()
        : new Date(),
    };

    dispatch(updatePublisherFilterData(filterData));
    setOpen(false);
  };

  // get all publisher data
  useEffect(() => {
    GetPublisherList({
      endPoint: publisherModuleApiEndPoints.getAllPubs,
      method: "POST",
      data: {
        data: {
          index: "0",
          itemPerIndex: "5000",
          fromDate: moment(currentDate).toISOString(),
          toDate: moment(new Date()).toISOString(),
          publisherNames: [],
          emails: [],
        },
      },
    });
  }, []);

  useEffect(() => {
    if (PublisherList?.isSuccess && PublisherList?.data?.data?.publishers) {
      const publisherOptionsSet = new Set();
      const publisherOptions = PublisherList?.data?.data?.publishers
        .map((pub) => {
          const trimmedPublisherName = pub?.publisherName?.trim();
          if (
            trimmedPublisherName &&
            !publisherOptionsSet.has(trimmedPublisherName)
          ) {
            publisherOptionsSet.add(trimmedPublisherName);
            return {
              label: trimmedPublisherName,
              value: trimmedPublisherName,
            };
          }
          return null;
        })
        .filter(Boolean);
      dispatch(updatePubOptions(publisherOptions));
    }
  }, [PublisherList]);

  // Call setFormValue after initialization
  useEffect(() => {
    if (previousFilterData && Object.keys(previousFilterData)?.length) {
      setFormValue(previousFilterData);
    }
  }, [previousFilterData]);

  // Set Form Value
  const setFormValue = (data) => {
    console.log("0-0-0-0-0-0-0-0", data);
    const dateRange = {
      startDate: new Date(data?.fromDate),
      endDate: data?.toDate,
      key: "selection",
    };

    setValue("status", data?.status, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("publisher", data?.publisher, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("label", data?.label, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
    setValue("dateRange", dateRange, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
  };
  //Push new option All
  const AllOptions = { label: "All", id: 1, value: "All" };

  const [statusOptions, setStatusOptions] = useState([
    { label: "Active", value: "true" },
    { label: "Disabled", value: "false" },
  ]);

  const [publisherOptions, setPublisherOptions] = useState([]);

  // Pushing the AllOptions object into the statusOptions array
  useEffect(() => {
    setStatusOptions((prevOptions) => [AllOptions, ...prevOptions]);
  }, []);

  useEffect(() => {
    setPublisherOptions((prevOptions) => [AllOptions, ...pubOptions]);
  }, [pubList]);

  return (
    <>
      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={() => setOpen(false)}
        sx={{
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            top: "15px",
            borderRadius: "16px",
            maxWidth: "500px",
            width: "100%",
          },
        }}
      >
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent={"space-between"}
          >
            <Box marginBottom={"15px"}>
              <Typography component="h6" variant="h6" fontWeight={600}>
                Filters
              </Typography>
            </Box>
            <Box marginBottom={"15px"}>
              <Typography
                component="h7"
                variant="h7"
                fontWeight={200}
                style={{ cursor: "pointer" }}
                onClick={ClearFilters}
              >
                Clear All
              </Typography>
            </Box>
          </Stack>

          <Divider
            sx={{
              mb: "20px",
            }}
          />

          <Box component={"form"} className="accordionGrid">
            <Accordion
              className="accordionWrapper"
              defaultExpanded={
                defaultCampaign == "status"
                  ? true
                  : defaultCampaign == "all"
                  ? true
                  : false
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                className="accordionSummary"
              >
                <Typography className="filterTitle">
                  Status{" "}
                  {AllFilterLength?.status &&
                    AllFilterLength?.status.length > 0 && (
                      <span className="count-style">
                        <p>{AllFilterLength?.status.length}</p>
                      </span>
                    )}
                </Typography>
              </AccordionSummary>

              <AccordionDetails sx={{ backgroundColor: "#e0e0e05e" }}>
                {/* <GlobalInput control={control} name="status" label="" /> */}
                {/* <GlobalMultiSelect
                  control={control}
                  size="small"
                  name="status"
                  label=""
                  // options={statusOptions}
                  options={statusOptions}
                  // setSelectedOptions={setSelectedOptions}
                  required
                  error={!!errors.status}
                  helperText={errors.status?.message}
                /> */}

                <GlobalDropdown
                  control={control}
                  size="small"
                  name="status"
                  options={statusOptions}
                  required
                  error={!!errors.status}
                  helperText={errors.status?.message}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion
              className="accordionWrapper"
              defaultExpanded={
                defaultCampaign == "name"
                  ? true
                  : defaultCampaign == "all"
                  ? true
                  : false
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                className="accordionSummary"
              >
                <Typography className="filterTitle">
                  Publisher{" "}
                  {AllFilterLength?.publisherOptions &&
                    AllFilterLength?.publisherOptions?.length > 0 && (
                      <span className="count-style">
                        <p>{AllFilterLength?.publisherOptions?.length}</p>
                      </span>
                    )}
                </Typography>
              </AccordionSummary>

              <AccordionDetails sx={{ backgroundColor: "#e0e0e05e" }}>
                <GlobalMultiSelect
                  control={control}
                  size="small"
                  name="publisher"
                  label=""
                  options={publisherOptions}
                  required
                  error={!!errors.publisher}
                  helperText={errors.publisher?.message}
                />
              </AccordionDetails>
            </Accordion>
            <Accordion
              className="accordionWrapper"
              defaultExpanded={
                defaultCampaign == "label"
                  ? true
                  : defaultCampaign == "all"
                  ? true
                  : false
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                className="accordionSummary"
              >
                <Typography className="filterTitle">
                  Label{" "}
                  {AllFilterLength?.label &&
                    AllFilterLength?.label.length > 0 && (
                      <span className="count-style">
                        <p>{AllFilterLength?.label.length}</p>
                      </span>
                    )}
                </Typography>
              </AccordionSummary>

              <AccordionDetails sx={{ backgroundColor: "#e0e0e05e" }}>
                {/* <GlobalDropdown control={control} name="label" options={[]} /> */}
                <GlobalMultiSelect
                  control={control}
                  size="small"
                  name="label"
                  label=""
                  options={[]}
                  required
                  error={!!errors.label}
                  helperText={errors.label?.message}
                />
              </AccordionDetails>
            </Accordion>
            {/* </Grid> */}

            {/* <Grid item xs={12}> */}
            <Accordion
              className="accordionWrapper"
              defaultExpanded={
                defaultCampaign == "created"
                  ? true
                  : defaultCampaign == "all"
                  ? true
                  : false
              }
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                className="accordionSummary"
              >
                <Typography className="filterTitle">Create Date</Typography>
              </AccordionSummary>

              <AccordionDetails sx={{ backgroundColor: "#e0e0e05e" }}>
                <Controller
                  name="dateRange"
                  control={control}
                  render={({ field }) => (
                    <GlobalDateRangePicker
                      defaultRange={{
                        startDate: new Date(selectedDateFilter.fromDate),
                        endDate: new Date(selectedDateFilter.endDate),
                        key: "selection",
                      }}
                      {...field}
                    />
                  )}
                />
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Stack
            direction="row"
            component={Box}
            useFlexGap
            flexWrap="wrap"
            justifyContent={"flex-end"}
            bottom={0}
            marginTop={"1rem"}
          >
            <Box marginBottom={"15px"} marginRight={"5px"}>
              <CustomButton
                color="#3a5396"
                // label="Add Campaign"
                label="Apply Filter"
                onClick={handleSubmit(onFormSubmit)}
              />
            </Box>
            <Box marginBottom={"15px"}>
              <CustomButton
                labelColor="#7a7870"
                variant="outlined"
                color="white"
                label="Close"
                onClick={() => setOpen(false)}
              />
            </Box>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default PublisherFilters;
