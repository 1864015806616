import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};
const buyersOptions = createSlice({
  name: "buyersOptions",
  initialState,
  reducers: {
    updateBuyesOptions: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { updateBuyesOptions } = buyersOptions.actions;
export default buyersOptions.reducer;
