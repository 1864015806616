import React from "react";
import {
  Grid,
  Chip,
  useEffect,
  useState,
  Box,
  moment,
  PageHeader,
  useNavigate,
  useLocation,
  Card,
  AddOutlined,
  AddIcon,
  ArrowBack,
} from "../../Collections/Imports";
import "@inovua/reactdatagrid-community/theme/blue-light.css";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/index.css";
import "./../BuyerListing/style.css";
import AddEditCampaign from "../../Forms/AddEditCampaign";
import Filters from "../../Components/UIComponents/Filters";
import { useApiCallMutation } from "../../Services/ApiCallService";
import { campaignModuleAPiEndPoints } from "../../Config/APIsEndpoints";
import { useDispatch, useSelector } from "react-redux";
import { updateBuyersData } from "../../Store/BuyerModule";
import AgGridComponent from "../../Components/CommonComponents/AG-Grid";

import CircularProgressBar from "../../Components/UIComponents/CircularProgressBar";
import { updateBuyersFilterData } from "../../Store/BuyerModule/filterSession";
import { Outlet } from "react-router-dom";
import { updateCampaignRowData } from "../../Store/CampaignModule/currentCampaignRowData";
import CampaignFilters from "../../Forms/CampaignFilters";
import { updateCampaignsData } from "../../Store/CampaignModule";

const CampaignListingPage = () => {
  const navigate = useNavigate();
  let location = useLocation();
  // Form pagination handler
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [itemPerIndex, setItemPerIndex] = useState(100);
  const perPageCountOptions = [
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "1000", value: 1000 },
  ];
  const statusOptions = [
    { label: "Active", value: "true" },
    { label: "Inactive", value: "false" },
  ];

  const [rowSelected, setRowSelected] = useState({});
  const [campainName, setCampainName] = useState("");
  const [isOpenAddEditBox, setIsOpenAddEditBox] = useState(false);
  const [isOpenCampaignFilters, setIsOpenCampaignFilter] = useState(false);
  const [isOpenAddEditBuyerConfig, setIsOpenAddEditBuyerConfig] =
    useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const [defaultCampaign, setDefaultCampaign] = useState(false);

  const dispatch = useDispatch();
  const TableData = useSelector((state) => state.CampaignData?.tableData);
  const previousFilterData = useSelector(
    (state) => state?.campaignDataFilterSession?.filterData
  );
  const labelOptions = useSelector((state) => state?.labelOptions?.data);
  const typeOptions = useSelector((state) => state?.typeOptions?.data);
  const buyersOptions = useSelector((state) => state?.buyersOptions?.data);

  //Api Initialization
  const [GetAllCampaign, AllCampaigns] = useApiCallMutation();
  const [UpdateCampaign, UpdateCampaignData] = useApiCallMutation();

  // console.log("CampaignData", AllCampaigns);

  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 2);

  useEffect(() => {
    return () => {
      dispatch(updateBuyersFilterData({}));
    };
  }, []);

  useEffect(() => {
    if (location?.pathname === "/campaigns") {
      setCampainName("");
    } else {
      const currentCampData = JSON.parse(
        sessionStorage.getItem("currentCampaignData")
      );
      setCampainName(currentCampData.campaignName);
    }
  }, [location]);

  useEffect(() => {
    GetAllCampaign({
      endPoint: campaignModuleAPiEndPoints.getAllCampaigns,
      method: "POST",
      data: {
        data: {
          index: paginationIndex,
          itemPerIndex: itemPerIndex,
          buyerNames: previousFilterData?.buyerName || [],
          labelIds: previousFilterData?.label || [],
          typeIds: previousFilterData?.type || [],
          status: previousFilterData?.status || [],
          fromDate: moment(previousFilterData?.fromDate).toISOString(),
          toDate:
            moment(previousFilterData?.toDate).toISOString() == ""
              ? moment(selectedDateFilter?.endDate).toISOString()
              : moment(previousFilterData?.toDate).toISOString(),
        },
      },
    });
  }, [paginationIndex, itemPerIndex, previousFilterData]);

  useEffect(() => {
    if (
      AllCampaigns?.isSuccess &&
      AllCampaigns?.data?.data?.campaigns?.length
    ) {
      const campaignDataList = [...AllCampaigns?.data?.data?.campaigns];
      const tableData = {
        data: campaignDataList,
        headers: AllCampaigns?.data?.data?.headers,
      };

      dispatch(updateCampaignsData(tableData));
    }
  }, [AllCampaigns]);

  // get Preselected Filter label
  const getFilterLabel = (filterType, options) => {
    if (options?.length === 1) {
      return (
        <span>
          <Chip label={options[0]?.label} sx={{ m: "2px" }} size="small" />
        </span>
      );
    } else if (options?.length > 1) {
      return (
        <>
          <span>
            <Chip label={options[0]?.label} sx={{ m: "2px" }} size="small" />
          </span>
          <span
            style={{ cursor: "pointer", color: "#000", paddingLeft: "4px" }}
          >
            {`+${previousFilterData[filterType]?.length - 1}`}
          </span>
        </>
      );
    } else {
      return (
        <span>
          <Chip label={"All"} sx={{ m: "2px" }} size="small" />
        </span>
      );
    }
  };

  // set Preselected Filter label
  const setFilterLabel = (filterType) => {
    switch (filterType) {
      case "name":
        const campaignoptions = campaignoptions?.filter((opt) =>
          previousFilterData?.name?.find((val) => val == opt.value)
        );
        return getFilterLabel("name", campaignoptions);
        break;
      case "buyerName":
        const buyersoptions = buyersOptions?.filter((opt) =>
          previousFilterData?.buyerName?.find((val) => val == opt.value)
        );
        return getFilterLabel("buyerName", buyersoptions);
        break;
      case "status":
        const statusoptions = statusOptions?.filter((opt) =>
          previousFilterData?.status?.find((val) => val == opt.value)
        );
        return getFilterLabel("status", statusoptions);
        break;
      case "label":
        let labeloptions = [];
        if (labelOptions.length > 0) {
          labeloptions = labelOptions?.label.filter((opt) =>
            previousFilterData?.label?.find((val) => val == opt.value)
          );
        }
        return getFilterLabel("label", labeloptions);
        break;
      case "type":
        let typeoptions = [];
        if (typeOptions.length > 0) {
          typeoptions = typeOptions?.label.filter((opt) =>
            previousFilterData?.type?.find((val) => val == opt.value)
          );
        }
        return getFilterLabel("type", typeoptions);
        break;
    }
  };

  const toggleDrawer = (ele) => {
    ele === "created"
      ? setDefaultCampaign("created")
      : ele === "type"
      ? setDefaultCampaign("type")
      : ele === "name"
      ? setDefaultCampaign("name")
      : ele === "buyerName"
      ? setDefaultCampaign("buyerName")
      : ele === "label"
      ? setDefaultCampaign("label")
      : ele === "status"
      ? setDefaultCampaign("status")
      : setDefaultCampaign(false);

    setIsOpenCampaignFilter((prev) => !prev);
  };

  const [selectedDateFilter, setSelectedDateFilter] = useState({
    startDate: new Date("July 01, 2020"),
    endDate: new Date(),
    key: "selection",
  });

  // Handle Grid Button ACtion
  const handleActions = (previousData, action) => {
    console.log("Prives DATA0---0--0-0-0-0-0-0-00-0-", previousData);

    switch (action) {
      case "EDIT":
        setRowSelected(previousData);
        setIsEditMode(true);
        setIsOpenAddEditBox(true);
        break;
      case "CONFIG":
        setRowSelected(previousData);
        sessionStorage.setItem(
          "currentCampaignData",
          JSON.stringify(previousData)
        );
        dispatch(updateCampaignRowData(previousData));
        const currentCampData = JSON.parse(
          sessionStorage.getItem("currentCampaignData")
        );
        setCampainName(currentCampData.campaignName);
        navigate(String(previousData?.uniqueId));

        break;
      case "Status":
        const data = {
          id: previousData?.campaignId,
          campaignName: previousData?.campaignName,
          timezone: previousData?.timezone,
          campaignType: previousData?.campaignType,
          minPrice: previousData?.minPrice,
          campaignIntegrations: previousData?.campaignIntegrations,
          status: previousData?.status,
        };

        UpdateCampaign({
          endPoint: campaignModuleAPiEndPoints.updateCampaign,
          method: "POST",
          data: { data },
        });
        break;
    }
  };

  const handlePageChange = (data) => {
    setPaginationIndex(data);
  };
  const handleRowsPerPageChange = (data) => {
    setItemPerIndex(data);
    setPaginationIndex(0);
  };

  return (
    <>
      <Box>
        <PageHeader
          title={campainName || "Campaigns"}
          helpText={
            "Campaigns are used to qualify incoming publishers’ traffic against buyers' requirements. Here are some ways by which this is accomplished: Global Settings, Campaign Offer, Duplicate Checks, Do Not Present Lists, Schedules, Integrations, Notifications, Reject Groups, Cap Groups, Publisher Filters, Traffic Quality, Filters, Test Leads."
          }
          backIcon={location?.pathname === "/campaigns" ? null : <ArrowBack />}
          onBack={() => navigate(-1)}
        />

        {location?.pathname === "/campaigns" && (
          <>
            {/* Filters Start*/}
            <Box sx={{ pl: "0px", pr: "10px" }} mt={3} mb={1}>
              <Grid container>
                <Filters title="Name :" onClick={() => toggleDrawer("name")}>
                  {setFilterLabel("campaignName")}
                </Filters>
                <Filters
                  title="Buyer :"
                  onClick={() => toggleDrawer("buyerName")}
                >
                  {setFilterLabel("buyerName")}
                </Filters>
                <Filters
                  title="Status :"
                  onClick={() => toggleDrawer("status")}
                >
                  {setFilterLabel("status")}
                </Filters>
                <Filters
                  title="Created :"
                  onClick={() => toggleDrawer("created")}
                >
                  <Box>
                    <span style={{ fontSize: "14px" }}>
                      <Chip
                        label={moment(
                          previousFilterData?.fromDate
                            ? previousFilterData?.fromDate
                            : selectedDateFilter?.startDate
                        ).format("ll")}
                        sx={{ m: "2px" }}
                        size="small"
                      />
                    </span>{" "}
                    {"-"}{" "}
                    <span style={{ fontSize: "14px" }}>
                      <Chip
                        label={moment(
                          previousFilterData?.toDate
                            ? previousFilterData?.toDate
                            : selectedDateFilter?.endDate
                        ).format("ll")}
                        sx={{ m: "2px" }}
                        size="small"
                      />
                    </span>
                  </Box>
                </Filters>
                <Filters title="Label :" onClick={() => toggleDrawer("label")}>
                  {setFilterLabel("label")}
                </Filters>
                <Filters title="Type :" onClick={() => toggleDrawer("type")}>
                  {setFilterLabel("type")}
                </Filters>
                <Filters
                  icons={<AddIcon />}
                  title="Filters"
                  onClick={() => toggleDrawer("filters")}
                ></Filters>
                <Filters
                  title="Clear All"
                  onClick={() => dispatch(updateBuyersFilterData({}))}
                ></Filters>
              </Grid>
            </Box>
            {/* Filters End*/}

            {AllCampaigns.status === "isUninitialized" && (
              <CircularProgressBar />
            )}
            {AllCampaigns.status !== "isUninitialized" && (
              <Box>
                <AgGridComponent
                  rowData={TableData?.data || []}
                  columnDefs={TableData.headers || []}
                  actions={["EDIT", "CONFIG"]}
                  performAction={handleActions}
                  totalPageCount={AllCampaigns.data?.data?.total}
                  paginationIndex={paginationIndex}
                  itemPerIndex={itemPerIndex}
                  perPageCountOptions={perPageCountOptions}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleRowsPerPageChange}
                  rowMode
                  addAction={setIsOpenAddEditBox}
                  AddButtonName={"Add New Campaign"}
                  addActionIcon={<AddOutlined />}
                  downlodBtn={true}
                  tableName={"Campaigns"}
                />
              </Box>
            )}

            {isOpenAddEditBox && (
              <AddEditCampaign
                defaultValues={rowSelected}
                isOpen={isOpenAddEditBox}
                setOpen={setIsOpenAddEditBox}
                isEditMode={isEditMode}
                setIsEditMode={setIsEditMode}
              />
            )}

            {isOpenCampaignFilters && (
              <CampaignFilters
                defaultValues={rowSelected}
                isOpen={isOpenCampaignFilters}
                setOpen={setIsOpenCampaignFilter}
                isEditMode={isEditMode}
                defaultCampaign={defaultCampaign}
              />
            )}
          </>
        )}
        <div>
          <Outlet />
        </div>
      </Box>
    </>
  );
};
export default CampaignListingPage;
