import React from "react";
import { useForm } from "react-hook-form";
import { Card, Divider, Drawer, Stack, Typography } from "@mui/material";
import {
  Grid,
  useEffect,
  useState,
  Box,
  moment,
} from "../../Collections/Imports";

import GlobalInput from "../../Components/CommonComponents/GlobalInput";
import GlobalDropdown from "../../Components/CommonComponents/GlobalDropdown";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  buyerModuleAPiENdPoints,
  campaignModuleAPiEndPoints,
  coreAPIEndsPoints,
} from "../../Config/APIsEndpoints";
import {
  useApiCallMutation,
  useGetApiCallQuery,
} from "../../Services/ApiCallService";
import { useDispatch, useSelector } from "react-redux";
import GlobalMultiSelect from "../../Components/CommonComponents/GlobalMultiSelect";
import CustomButton from "../../Components/CommonComponents/GlobalButton";
import { updateBuyesOptions } from "../../Store/Global/BuyersOptions";
import {
  addSingleCampaignData,
  updateSingleCampaignData,
} from "../../Store/CampaignModule";

const AddEditCampaign = (props) => {
  const { setOpen, isOpen, defaultValues, isEditMode, setIsEditMode } = props;

  const [defaultValuesSchema, setDefaultValuesSchema] = useState({});
  const buyersOptions = useSelector((state) => state?.buyersOptions?.data);
  const dispatch = useDispatch();

  // Api Call Intialization
  const [CreateCampaign, creatCampaignData] = useApiCallMutation();
  const [UpdateCampaign, UpdateCampaignData] = useApiCallMutation();

  //Set Incoming Default values

  const [selectedDateFilter, setSelectedDateFilter] = useState({
    startDate: new Date("July 01, 2020"),
    endDate: new Date(),
    key: "selection",
  });
  const [GetAllbuyers, AllbuyersData] = useApiCallMutation();
  // Update Created Buyer
  useEffect(() => {
    if (AllbuyersData?.isSuccess && AllbuyersData?.data?.data?.buyers?.length) {
      const buyersOptions = AllbuyersData?.data?.data?.buyers.map((buyer) => {
        return { label: buyer?.buyerName, value: buyer?.id };
      });
      dispatch(updateBuyesOptions(buyersOptions));
    }
  }, [AllbuyersData]);
  useEffect(() => {
    GetAllbuyers({
      endPoint: buyerModuleAPiENdPoints.getAllbuyers,
      method: "POST",
      data: {
        data: {
          index: "all",
          itemPerIndex: "all",
          buyerNames: [],
          labelIds: [],
          typeIds: [],
          fromDate: moment(selectedDateFilter?.startDate).toISOString(),
          toDate: moment(selectedDateFilter?.endDate).toISOString(),
        },
      },
    });
  }, []);
  // Set Initial Value here
  const initialValues = {
    campaignName: "",
    buyerId: "",
    campaignType: "",
    timezone: "",
    minPrice: "",
    // campaignIntegrations: "",
  };

  // Set Validation Schema here
  const validationSchema = Yup.object().shape({
    campaignName: Yup.string()
      .trim("Name cannot include leading and trailing spaces")
      .required("This Field is required"),
    buyerId: Yup.string().required("This Field is required"),
    campaignType: Yup.string().required("This Field is required"),
    timezone: Yup.string().required("This Field is required"),
    minPrice: Yup.string()
      .matches(/^[0-9]+$/, "Only digits are allowed")
      .transform((value, originalValue) => {
        if (typeof originalValue === "string") {
          originalValue = originalValue.trim(); // Trim leading and trailing spaces if it's a string
        }
        return originalValue;
      })
      .min(0)
      .max(1000)
      .required("This Field is required"),
  });

  // initialise Form here
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: "all",
  });

  // Set Forms Value

  const SetFormValues = (data) => {
    console.log("SetData", data);
    setValue("campaignName", data?.campaignName, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("buyerId", data?.buyerId, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

    setValue("campaignType", data?.campaignType, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

    setValue("timezone", data?.timezone, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });
    setValue("minPrice", data?.minPrice, {
      shouldValidate: true,
      shouldDirty: true,
      shouldTouch: true,
    });

    // setValue("campaignIntegrations", data?.campaignIntegrations, {
    //   shouldValidate: true,
    //   shouldDirty: true,
    //   shouldTouch: true,
    // });
    // setValue("buyerStatus", data?.buyerStatus, {
    //   shouldValidate: true,
    //   shouldDirty: true,
    //   shouldTouch: true,
    // });
  };

  //Set Incoming Default values
  useEffect(() => {
    if (Object.keys(defaultValues).length && isEditMode) {
      SetFormValues(defaultValues);
    }
  }, []);

  // Update Created Campaign
  useEffect(() => {
    if (creatCampaignData?.isSuccess && !creatCampaignData?.data?.error) {
      dispatch(addSingleCampaignData(creatCampaignData.data?.data));
      setOpen(false);
      setIsEditMode(false);
    }
  }, [creatCampaignData]);

  useEffect(() => {
    if (UpdateCampaignData?.isSuccess && !UpdateCampaignData?.data?.error) {
      // console.log("All Campaign DATA", UpdateCampaignData.data?.data);
      dispatch(updateSingleCampaignData(UpdateCampaignData.data?.data));
      setOpen(false);
      setIsEditMode(false);
    }
  }, [UpdateCampaignData]);

  //***********FORM SUBMISSION ********//
  const onFormSubmit = (data) => {
    if (isEditMode) {
      const modifiedData = data;
      modifiedData.id = defaultValues?.campaignId;

      UpdateCampaign({
        endPoint: campaignModuleAPiEndPoints.updateCampaign,
        method: "POST",
        data: { data: modifiedData },
      });
    } else {
      CreateCampaign({
        endPoint: campaignModuleAPiEndPoints.createCampaign,
        method: "POST",
        data: { data },
      });
    }
  };

  const integrations = [
    { label: "Redirect", id: 1, value: "1" },
    { label: "Integration 1", id: 2, value: "2" },
  ];
  const campaignTypeData = [
    { label: "Redirect", value: "redirect", id: 1 },
    { label: "Silence", value: "silence", id: 2 },
  ];
  const timeZone = [
    { label: "London (BST/GMT)", value: "Europe/London" },
    { label: "New York (EST/EDT)", value: "America/New_York" },
    { label: "Chicago (CST/CDT)", value: "America/Chicago" },
    { label: "Edmonton (MST/MDT)", value: "America/Edmonton" },
    { label: "Los Angeles (PST/PDT)", value: "America/Los_Angeles" },
  ];
  return (
    <>
      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={() => {
          setOpen(false);
          setIsEditMode(false);
        }}
        sx={{
          [`& .MuiDrawer-paper`]: {
            boxSizing: "border-box",
            top: "15px",
            borderRadius: "16px",
            width: "100%",
            maxWidth: "500px",
          },
        }}
      >
        <Box
          sx={{
            pt: "1rem",
            pr: "1rem",
            pl: "1rem",
          }}
        >
          <Stack
            spacing={{ xs: 1, sm: 2 }}
            direction="row"
            useFlexGap
            flexWrap="wrap"
            justifyContent={"space-between"}
          >
            <Box marginBottom={"15px"}>
              <Typography component="h6" variant="h6" fontWeight={600}>
                {(isEditMode && "Update Campaign") || "Add New Campaign"}
              </Typography>
            </Box>
            <Box marginBottom={"15px"} onClick={() => reset()}>
              <Typography component="h7" variant="h7" fontWeight={200}>
                Clear All
              </Typography>
            </Box>
          </Stack>

          <Divider
            sx={{
              mb: "20px",
            }}
          />

          <Box component={"form"}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <GlobalInput
                  control={control}
                  size="small"
                  name="campaignName"
                  label="Campaign Name"
                  error={!!errors.campaignName}
                  helperText={errors.campaignName?.message}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  size="small"
                  name="buyerId"
                  label="Buyer"
                  options={buyersOptions}
                  error={!!errors.buyer}
                  helperText={errors.buyer?.message}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  size="small"
                  name="campaignType"
                  label="Campaign Type"
                  options={campaignTypeData}
                  error={!!errors.campaignType}
                  helperText={errors.campaignType?.message}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  size="small"
                  name="timezone"
                  label="Time Zone"
                  options={timeZone}
                  required
                  error={!!errors.timeZone}
                  helperText={errors.timeZone?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <GlobalInput
                  control={control}
                  size="small"
                  name="minPrice"
                  label="Min Price"
                  error={!!errors.minPrice}
                  helperText={errors.minPrice?.message}
                  required
                />
              </Grid>
              {/* <Grid item xs={12}>
                <GlobalDropdown
                  control={control}
                  size="small"
                  name="campaignIntegrations"
                  label="Integration"
                  options={integrations}
                  required
                  error={!!errors.campaignIntegrations}
                  helperText={errors.campaignIntegrations?.message}
                />
              </Grid> */}

              <Grid item xs={12}></Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            borderTop: "1px solid #DCE0E6",
            pr: "1rem",
            // pl: "1rem",
          }}
        >
          <Stack
            direction="row"
            component={Box}
            useFlexGap
            flexWrap="wrap"
            justifyContent={"flex-end"}
            bottom={0}
            marginTop={"1rem"}
          >
            <Box marginBottom={"15px"} marginRight={"5px"}>
              <CustomButton
                color="#3a5396"
                // label="Add Campaign"
                // onClick={handleSubmit(onFormSubmit)}
                label={isEditMode ? "Update Campaign" : "Add Campaign"}
                onClick={handleSubmit(onFormSubmit)}
              />
            </Box>
            <Box marginBottom={"15px"}>
              <CustomButton
                labelColor="#7a7870"
                variant="outlined"
                color="white"
                label="Close"
                onClick={() => {
                  setOpen(false);
                  setIsEditMode(false);
                }}
              />
            </Box>
          </Stack>
        </Box>
      </Drawer>
    </>
  );
};

export default AddEditCampaign;
