import { combineReducers } from "@reduxjs/toolkit";
import { ApiCallServices } from "../Services/ApiCallService";
import buyersData from "./BuyerModule";
import CampaignData from "./CampaignModule";
import ScheduleData from "./ScheduleModule";
import buyersDataFilterSession from "./BuyerModule/filterSession";
import snackbarConfig from "./Global/SnackBar";
import typeOptions from "./Global/TypeOptions";
import labelOptions from "./Global/LabelOptions";
import managerOptions from "./Global/ManagerOptions";
import buyersOptions from "./Global/BuyersOptions";
import currentCampaignRowData from "./CampaignModule/currentCampaignRowData";
import campaignDataFilterSession from "./CampaignModule/filterSession";
import currentScheduleRowData from "./ScheduleModule/currentScheduleRowData";
import publisherData from "./PublisherModule";
import publisherFilterSession from "./PublisherModule/filter";
import pubOptionsList from "./PublisherModule/PubOptions";
import filterByPub from "./FIlterByPub";
import currentCampaignFilterRowData from "./CampaignFilter/CampaignFilter";
import revShare from "./PublisherModule/revShare";
import bucketBank from "./PublisherModule/bucketBank";
import PubContactData from "./PublisherModule/PublisherContactModule";
import PubTrackData from "./PublisherModule/PublisherTrackingModule";
import ChannelData from "./ChannelModule";
import PlAndDnpl from "./PlDnplModule/";
import currentPlDnplRowData from "./PlDnplModule/currentPlDnplRowData";
import publisherPingtreeSetting from "./PublisherModule/publisherPingtreeSetting";
import plDnplDataFilterSession from "./PlDnplModule/filterSession";

import campaignTags from "./CampaignTags/index";
import campaignTagDataFilterSession from "./CampaignTags/filterSession";
import buyerTags from "./BuyerTagModule/index";
import buyersLabelDataFilterSession from "./BuyerLabelModule/filter";
import buyersLabelData from "./BuyerLabelModule/";
const createReducer = (asyncReducers) => (state, action) => {
  const combinedReducer = combineReducers({
    [ApiCallServices.reducerPath]: ApiCallServices.reducer,
    buyersData,
    buyersDataFilterSession,
    buyersLabelDataFilterSession,
    buyersLabelData,
    snackbarConfig,
    typeOptions,
    labelOptions,
    managerOptions,
    buyersOptions,
    currentCampaignRowData,
    campaignDataFilterSession,
    CampaignData,
    ScheduleData,
    currentScheduleRowData,
    publisherData,
    publisherFilterSession,
    filterByPub,
    currentCampaignFilterRowData,
    PubContactData,
    bucketBank,
    PubTrackData,
    ChannelData,
    PlAndDnpl,
    currentPlDnplRowData,
    publisherPingtreeSetting,
    plDnplDataFilterSession,
    campaignTags,
    campaignTagDataFilterSession,
    buyerTags,
    pubOptionsList,
  });

  return combinedReducer(state, action);
};

export default createReducer;
