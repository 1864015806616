import { createSlice, nanoid } from "@reduxjs/toolkit";

const initialState = {
  tableData: {
    data: [],
    headers: [],
  },
};

const buyersData = createSlice({
  name: "buyersData",
  initialState,
  reducers: {
    updateBuyersData: (state, action) => {
      state.tableData = action.payload;
    },
    updateSingleBuyerData: (state, action) => {
      const updatedRow = action.payload;
      const index = state.tableData.data.findIndex(
        (data) => data?.id === updatedRow?.id
      );
      const keys = Object.keys(updatedRow);
      keys.map((key) => {
        state.tableData.data[index][key] = updatedRow[key];
      });
    },
    addSingleBuyerData: (state, action) => {
      state.tableData.data.unshift(action.payload);
    },
    removeSingleBuyerData: (state, action) => {
      state.tableData = state.tableData.filter(
        (data) => data.id !== action.payload
      );
    },
  },
});

export const {
  updateBuyersData,
  updateSingleBuyerData,
  addSingleBuyerData,
  removeSingleBuyerData,
} = buyersData.actions;
export default buyersData.reducer;
