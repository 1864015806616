import {
  Grid,
  Chip,
  useEffect,
  useState,
  Box,
  moment,
  PageHeader,
  AddOutlined,
  AddIcon,
} from "../../Collections/Imports";
import "@inovua/reactdatagrid-community/theme/blue-light.css";
import "@inovua/reactdatagrid-community/base.css";
import "@inovua/reactdatagrid-community/index.css";
import "./style.css";
import AddEditBuyer from "../../Forms/AddEditBuyer";
import Filters from "../../Components/UIComponents/Filters";
import { useApiCallMutation } from "../../Services/ApiCallService";
import { buyerModuleAPiENdPoints } from "../../Config/APIsEndpoints";
import { useDispatch, useSelector } from "react-redux";
import {
  addSingleBuyerData,
  updateBuyersData,
  updateSingleBuyerData,
} from "../../Store/BuyerModule";
import AgGridComponent from "../../Components/CommonComponents/AG-Grid";
import AddEditBuyerConfig from "../../Forms/AddEditBuyerConfig";
import BuyerFilters from "../../Forms/BuyerFilters";
import CircularProgressBar from "../../Components/UIComponents/CircularProgressBar";
import { updateBuyersFilterData } from "../../Store/BuyerModule/filterSession";
import { OutboxOutlined } from "@mui/icons-material";

const BuyerListingPage = () => {
  // Form pagination handler
  const [paginationIndex, setPaginationIndex] = useState(0);
  const [itemPerIndex, setItemPerIndex] = useState(100);
  const perPageCountOptions = [
    { label: "100", value: 100 },
    { label: "500", value: 500 },
    { label: "1000", value: 1000 },
  ];
  const statusOptions = [
    { label: "Active", value: "true" },
    { label: "Inactive", value: "false" },
  ];

  const [rowSelected, setRowSelected] = useState({});
  const [isOpenAddEditBuyer, setIsOpenAddEditBuyer] = useState(false);
  const [isOpenBuyerFilter, setIsOpenBuyerFilter] = useState(false);
  const [isOpenAddEditBuyerConfig, setIsOpenAddEditBuyerConfig] =
    useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const [defaultBuyers, setDefaultBuyers] = useState(false);

  const dispatch = useDispatch();
  const TableData = useSelector((state) => state.buyersData?.tableData);

  const previousFilterData = useSelector(
    (state) => state?.buyersDataFilterSession?.filterData
  );
  const labelOptions = useSelector((state) => state?.labelOptions?.data);
  const typeOptions = useSelector((state) => state?.typeOptions?.data);
  const buyersOptions = useSelector((state) => state?.buyersOptions?.data);

  const [selectedDateFilter, setSelectedDateFilter] = useState({
    startDate: new Date("July 01, 2020"),
    endDate: new Date(),
    key: "selection",
  });

  //Api Initialization
  const [GetAllbuyers, AllbuyersData] = useApiCallMutation();
  const [UpdateBuyer, UpdateBuyerData] = useApiCallMutation();

  console.log("prevData", previousFilterData.fromDate);
  useEffect(() => {
    GetAllbuyers({
      endPoint: buyerModuleAPiENdPoints.getAllbuyers,
      method: "POST",
      data: {
        data: {
          index: paginationIndex,
          itemPerIndex: itemPerIndex,
          buyerNames: previousFilterData?.buyerName || [],
          labelIds: previousFilterData?.label || [],
          typeIds: previousFilterData?.type || [],
          status: previousFilterData?.status || [],
          fromDate: moment(previousFilterData?.fromDate).toISOString(),
          toDate:
            moment(previousFilterData?.toDate).toISOString() == ""
              ? moment(selectedDateFilter?.endDate).toISOString()
              : moment(previousFilterData?.toDate).toISOString(),
        },
      },
    });
  }, [paginationIndex, itemPerIndex, previousFilterData]);

  useEffect(() => {
    if (AllbuyersData?.isSuccess) {
      const tableData = {
        data: AllbuyersData?.data?.data?.buyers,
        headers: AllbuyersData?.data?.data?.headers,
      };
      dispatch(updateBuyersData(tableData));
    }
  }, [AllbuyersData]);

  useEffect(() => {
    return () => {
      dispatch(updateBuyersFilterData({}));
    };
  }, []);
  //dispatch(updateBuyersFilterData({fromDate:moment(selectedDateFilter?.startDate).toISOString(),toDate:moment(selectedDateFilter?.endDate).toISOString()}));

  useEffect(() => {
    if (UpdateBuyerData?.isSuccess && !UpdateBuyerData?.data?.error) {
      dispatch(updateSingleBuyerData(UpdateBuyerData.data?.data));
    }
  }, [UpdateBuyerData]);

  // get Preselected Filter label
  const getFilterLabel = (filterType, options) => {
    if (options?.length === 1) {
      return (
        <span>
          <Chip label={options[0]?.label} sx={{ m: "2px" }} size="small" />
        </span>
      );
    } else if (options?.length > 1) {
      return (
        <>
          <span>
            <Chip label={options[0]?.label} sx={{ m: "2px" }} size="small" />
          </span>
          <span
            style={{ cursor: "pointer", color: "#000", paddingLeft: "4px" }}
          >
            {`+${previousFilterData[filterType]?.length - 1}`}
          </span>
        </>
      );
    } else {
      return (
        <span>
          <Chip label={"All"} sx={{ m: "2px" }} size="small" />
        </span>
      );
    }
  };

  // set Preselected Filter label
  const setFilterLabel = (filterType) => {
    switch (filterType) {
      case "buyerName":
        const buyersoptions = buyersOptions?.filter((opt) =>
          previousFilterData?.buyerName?.find((val) => val == opt.value)
        );
        return getFilterLabel("buyerName", buyersoptions);
        break;
      case "status":
        let statusoptions = [];
        if (statusOptions.length > 0) {
          statusoptions = statusOptions?.filter((opt) =>
            previousFilterData?.status?.find((val) => val == opt.value)
          );
        }
        console.log(statusOptions, previousFilterData?.status);
        return getFilterLabel("status", statusoptions);
        break;
      case "label":
        let labeloptions = [];
        if (labelOptions.length > 0) {
          labeloptions = labelOptions?.label.filter((opt) =>
            previousFilterData?.label?.find((val) => val == opt.value)
          );
        }
        console.log(labelOptions?.label, previousFilterData?.label);

        return getFilterLabel("label", labeloptions);
        break;
      case "type":
        let typeoptions = [];
        if (typeOptions.length > 0) {
          typeoptions = typeOptions?.type.filter((opt) =>
            previousFilterData?.type?.find((val) => val == opt.value)
          );
        }

        return getFilterLabel("type", typeoptions);
        break;
    }
  };

  const toggleDrawer = (ele) => {
    ele === "created"
      ? setDefaultBuyers("created")
      : ele === "name"
      ? setDefaultBuyers("name")
      : ele === "type"
      ? setDefaultBuyers("type")
      : ele === "label"
      ? setDefaultBuyers("label")
      : ele === "status"
      ? setDefaultBuyers("status")
      : setDefaultBuyers(false);

    setIsOpenBuyerFilter((prev) => !prev);
  };

  // Handle Grid Button ACtion
  const handleActions = (previousData, action) => {
    switch (action) {
      case "EDIT":
        console.log(previousData);
        setRowSelected(previousData);
        setIsEditMode(true);
        setIsOpenAddEditBuyer(true);
        break;
      case "CONFIG":
        setRowSelected(previousData);
        setIsOpenAddEditBuyerConfig(true);
        setIsEditMode(true);
        break;
      case "Status":
        const data = {
          id: previousData?.id,
          buyerName: previousData?.buyerName,
          managerId: previousData?.managerId,
          labelId: previousData?.labelId,
          buyerEmail: previousData?.buyerEmail,
          mobileNo: previousData?.mobileNo,
          typeId: previousData?.typeId,
          buyerAddress: previousData?.buyerAddress,
          buyerIntegrations: previousData?.buyerIntegrations,
          buyerStatus: !previousData?.buyerStatus,
        };

        UpdateBuyer({
          endPoint: buyerModuleAPiENdPoints.updateBuyer,
          method: "POST",
          data: { data },
        });
        break;
    }
  };

  const handlePageChange = (data) => {
    setPaginationIndex(data);
  };
  const handleRowsPerPageChange = (data) => {
    setItemPerIndex(data);
    setPaginationIndex(0);
  };

  return (
    <>
      {/* <CommonReactDataGrid
                data={filterLeads}
                columns={columns}
                defaultFilterValue={tableFilter}
                setGridRef={setGridRef}
                isFullscreen={isFullscreen2}
                showColumnMenuTool={false}
            /> */}
      <PageHeader
        title={"Buyers"}
        helpText={
          "List of all buyers with search filters, the dates created, and their statuses. To Create a new buyer, begin by clicking on the button - “Add New Buyer.” After completing all sections, click “Add” and refresh the page. In order to go into the buyer profile to complete setup, you will need to click on the tab title “Settings.” Here, you will set up all API, accounting, and contact information."
        }
      />
      {/* Filters Start*/}
      <Box sx={{ pl: "16px" }} mt={5}>
        <Grid container spacing={2}>
          <Filters title="Buyer Name :" onClick={() => toggleDrawer("name")}>
            {setFilterLabel("buyerName")}
          </Filters>
          <Filters title="Status :" onClick={() => toggleDrawer("status")}>
            {setFilterLabel("status")}
          </Filters>
          <Filters title="Created :" onClick={() => toggleDrawer("created")}>
            <Box>
              <span style={{ fontSize: "14px" }}>
                <Chip
                  label={moment(
                    previousFilterData?.fromDate
                      ? previousFilterData?.fromDate
                      : selectedDateFilter?.startDate
                  ).format("ll")}
                  sx={{ m: "2px" }}
                  size="small"
                />
              </span>

              <span style={{ fontSize: "14px" }}>
                <Chip
                  label={moment(
                    previousFilterData?.toDate
                      ? previousFilterData?.toDate
                      : selectedDateFilter?.endDate
                  ).format("ll")}
                  sx={{ m: "2px" }}
                  size="small"
                />
              </span>
            </Box>
          </Filters>
          <Filters title="Label :" onClick={() => toggleDrawer("label")}>
            {setFilterLabel("label")}
          </Filters>
          <Filters title="Type :" onClick={() => toggleDrawer("type")}>
            {setFilterLabel("type")}
          </Filters>
          <Filters
            title="Filters"
            icons={<AddIcon fontSize="small" />}
            onClick={() => toggleDrawer("filters")}
          ></Filters>
          <Filters
            title="Clear All"
            onClick={() => dispatch(updateBuyersFilterData({}))}
          ></Filters>
        </Grid>
      </Box>
      {/* Filters End*/}

      {/* <ReactDataGrid
        idProperty="id"
        style={gridStyle}
        columns={TableData.headers || []}
        dataSource={TableData.data || []}
        pagination
      /> */}

      {AllbuyersData.isLoading && <CircularProgressBar />}
      {!AllbuyersData.isLoading && (
        <Box>
          <AgGridComponent
            rowData={TableData?.data || []}
            columnDefs={TableData?.headers || []}
            actions={["EDIT"]}
            performAction={handleActions}
            totalPageCount={AllbuyersData.data?.data?.total}
            paginationIndex={paginationIndex}
            itemPerIndex={itemPerIndex}
            perPageCountOptions={perPageCountOptions}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            addAction={setIsOpenAddEditBuyer}
            AddButtonName={"Add New Buyer"}
            addActionIcon={<AddOutlined />}
            downlodBtn={true}
            tableName={"Buyers"}
            rowMode
          />
        </Box>
      )}

      {isOpenAddEditBuyer && (
        <AddEditBuyer
          defaultValues={rowSelected}
          isOpen={isOpenAddEditBuyer}
          setOpen={setIsOpenAddEditBuyer}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
        />
      )}

      {isOpenBuyerFilter && (
        <BuyerFilters
          defaultValues={rowSelected}
          isOpen={isOpenBuyerFilter}
          setOpen={setIsOpenBuyerFilter}
          isEditMode={isEditMode}
          defaultBuyers={defaultBuyers}
        />
      )}

      {isOpenAddEditBuyerConfig && (
        <AddEditBuyerConfig
          defaultValues={rowSelected}
          isOpen={isOpenAddEditBuyerConfig}
          setOpen={setIsOpenAddEditBuyerConfig}
          isEditMode={isEditMode}
        />
      )}
    </>
  );
};
export default BuyerListingPage;
