import React from "react";
import {
  Grid,
  Chip,
  useEffect,
  useState,
  Box,
  moment,
  PageHeader,
  useNavigate,
  useLocation,
  AddOutlined,
} from "../../Collections/Imports";
import Filters from "../../Components/UIComponents/Filters";
import { useApiCallMutation } from "../../Services/ApiCallService";
import { useDispatch, useSelector } from "react-redux";
import AgGridComponent from "../../Components/CommonComponents/AG-Grid";
import PublisherFilters from "../../Forms/PublisherFilters";

function ChannelListing() {
  const dispatch = useDispatch();
  const [rowSelected, setRowSelected] = useState({});
  const [defaultChannel, setDefaultChannel] = useState(false);
  const [isOpenAddEditBox, setIsOpenAddEditBox] = useState(false);
  const [isOpenChannelFilter, setIsOpenChannelFilter] = useState(false);
  let setFilterLabel = "";
  const previousFilterData = useSelector(
    (state) => state?.publisherFilterSession?.filterData
  );
  const [selectedDateFilter, setSelectedDateFilter] = useState({
    startDate: new Date("July 01, 2020"),
    endDate: new Date(),
    key: "selection",
  });
  const toggleDrawer = (ele) => {
    ele === "created"
      ? setDefaultChannel("created")
      : ele === "name"
      ? setDefaultChannel("name")
      : ele === "label"
      ? setDefaultChannel("label")
      : ele === "status"
      ? setDefaultChannel("status")
      : setDefaultChannel(false);

    setIsOpenChannelFilter((prev) => !prev);
  };
  //set Preselected Filter label
  //   const setFilterLabel = (filterType) => {
  //     switch (filterType) {
  //       case "publisher":
  //         const puboptions = pubOptions?.filter((opt) =>
  //           previousFilterData?.publisher?.find((val) => val == opt.value)
  //         );
  //         return getFilterLabel("publisher", puboptions);
  //         break;
  //       case "status":
  //         const statusoptions = statusOptions?.filter((opt) =>
  //           previousFilterData?.status?.find((val) => val == opt.value)
  //         );
  //         return getFilterLabel("status", statusoptions);
  //         break;
  //     }
  //   };

  return (
    <>
      {/* Page Header Start */}
      <PageHeader
        title={"Channels"}
        helpText={
          "This section allows you to view all publisher channels and provides you with the functionality to either activate or block a channel. All common channel information is displayed, such as the channel name, the publisher’s name, status, etc."
        }
      />
      {/* Page Header End */}
      {/* =========================================================================================== */}
      {/* Filters Start*/}
      <Box sx={{ pl: "16px" }} mt={5}>
        <Grid container spacing={2}>
          <Filters title="Publisher:" onClick={() => toggleDrawer("name")}>
            {/* {setFilterLabel("publisher")} */}
          </Filters>
          <Filters title="Status :" onClick={() => toggleDrawer("status")}>
            {/* {setFilterLabel("status")} */}
          </Filters>
          <Filters title="Created :" onClick={() => toggleDrawer("created")}>
            <Box>
              <span style={{ fontSize: "14px" }}>
                {moment(
                  previousFilterData?.fromDate
                    ? previousFilterData?.fromDate
                    : selectedDateFilter?.startDate
                ).format("ll")}
              </span>{" "}
              {"-"}{" "}
              <span style={{ fontSize: "14px" }}>
                {moment(
                  previousFilterData?.toDate
                    ? previousFilterData?.toDate
                    : selectedDateFilter?.endDate
                ).format("ll")}
              </span>
            </Box>
          </Filters>
          <Filters title="Label :" onClick={() => toggleDrawer("label")}>
            {/* {setFilterLabel("label")} */}
          </Filters>
          <Filters
            title="Filters"
            onClick={() => toggleDrawer("filters")}
          ></Filters>
          <Filters title="Clear All" onClick=""></Filters>
        </Grid>
      </Box>
      {/* Filters End*/}
      {/* =========================================================================================== */}
    </>
  );
}

export default ChannelListing;
