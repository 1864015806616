/**
 * The above code is a React component that renders a form for global settings and handles form
 * submission.
 * @returns The Global component is being returned.
 */
import React from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import {
  Grid,
  useEffect,
  Box,
  moment,
  Typography,
  Divider,
  Button,
  Chip,
} from "../../../../Collections/Imports";
import { publisherModuleApiEndPoints } from "../../../../Config/APIsEndpoints";
import { useSelector } from "react-redux";
import { useApiCallMutation } from "../../../../Services/ApiCallService";
//

const Main = () => {
  let currentPubData = JSON.parse(
    sessionStorage.getItem("currentPublisherData")
  );

  const [getPublisherSetting, PublisherSettingData] = useApiCallMutation();

  let singlePubData = "";

  if (PublisherSettingData) {
    singlePubData = PublisherSettingData?.data?.data;
  }

  console.log("Datata", singlePubData);

  const RegisterDate = moment(singlePubData?.createdOn).format(
    "MMM, DD YYYY, h:mm:ss a"
  );

  useEffect(() => {
    getPublisherSetting({
      endPoint: publisherModuleApiEndPoints.getPubById,
      method: "POST",
      data: {
        data: {
          pubUniqueId: currentPubData.pubUniqueId,
        },
      },
    });
  }, []);

  return (
    <div>
      <Box
        sx={{
          width: "50%",
          margin: "0px auto",
          borderRadius: "3px",
          border: "1px solid #D3D6DD",
          padding: "20px",
          background: "#fff",
        }}
      >
        <Box marginBottom={"15px"}>
          <Typography component="h6" variant="h6" fontWeight={600}>
            Main Setting
          </Typography>
        </Box>
        <Divider sx={{ mb: "20px" }} />

        <Grid
          container
          xs={12}
          spacing={2}
          // direction={"column"}
          sx={{ color: "#3D516E", fontSize: "14px", margin: "auto" }}
        >
          <Grid container xs={12} sx={{ background: "#F1F2F4" }}>
            <Grid item xs={4} sx={{ fontWeight: 600 }}>
              <Box p={1}>ID</Box>
            </Grid>
            <Grid item xs={8} display={"flex"}>
              <Box p={1}>{singlePubData?.pubUniqueId}</Box>
            </Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={4} sx={{ fontWeight: 600 }}>
              <Box p={1}>Nickname</Box>
            </Grid>
            <Grid item xs={8} display={"flex"}>
              <Box p={1}>
                {singlePubData?.pocFirstName} {singlePubData?.pocLastName}
              </Box>
            </Grid>
          </Grid>

          <Grid container xs={12} sx={{ background: "#F1F2F4" }}>
            <Grid item xs={4} sx={{ fontWeight: 600 }}>
              <Box p={1}>Main Email</Box>
            </Grid>
            <Grid item xs={8} display={"flex"}>
              <Box p={1}>{singlePubData?.email}</Box>
            </Grid>
          </Grid>

          <Grid container xs={12}>
            <Grid item xs={4} sx={{ fontWeight: 600 }}>
              <Box p={1}>Status</Box>
            </Grid>
            <Grid item xs={8} display={"flex"}>
              <Box p={1}>
                {" "}
                <Chip
                  size={"small"}
                  label={
                    <span>
                      {singlePubData?.status === true ? "Active" : "Disabled"}
                    </span>
                  }
                  color={singlePubData?.status === true ? "success" : "error"}
                  variant="outlined"
                  style={{ marginRight: "5px" }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container xs={12} sx={{ background: "#F1F2F4" }}>
            <Grid item xs={4} sx={{ fontWeight: 600 }}>
              <Box p={1}>Publisher Label</Box>
            </Grid>
            <Grid item xs={8} display={"flex"} alignItems={"center"}>
              <Box p={1}>
                <Button
                  size="small"
                  sx={{
                    borderRadius: "5px",
                    border: "1px solid #303D60",
                    background: "#303D60",
                    color: "#fff",
                    padding: "2px 5px",
                    fontSize: "10px",
                    ":hover": {
                      background: "#45678A", // Change to the desired hover color
                    },
                  }}
                >
                  # Set Labels
                </Button>
              </Box>
            </Grid>
          </Grid>

          <Grid container xs={12}>
            <Grid item xs={4} sx={{ fontWeight: 600 }}>
              <Box p={1}>Name</Box>
            </Grid>
            <Grid item xs={8} display={"flex"}>
              <Box p={1}>{singlePubData?.publisherName} </Box>
            </Grid>
          </Grid>

          <Grid container xs={12} sx={{ background: "#F1F2F4" }}>
            <Grid item xs={4} sx={{ fontWeight: 600 }}>
              <Box p={1}>Registration date</Box>
            </Grid>
            <Grid item xs={8} display={"flex"}>
              <Box p={1}>{RegisterDate}</Box>
            </Grid>
          </Grid>

          <Grid container xs={12}>
            <Grid item xs={4} sx={{ fontWeight: 600 }}>
              <Box p={1}>Groups</Box>
            </Grid>
            <Grid item xs={8} display={"flex"}>
              <Box p={1}>Publisher</Box>
            </Grid>
          </Grid>

          <Grid container xs={12} sx={{ background: "#F1F2F4" }}>
            <Grid item xs={4} sx={{ fontWeight: 600 }}>
              <Box p={1}>Publisher Agent</Box>
            </Grid>
            <Grid item xs={8} display={"flex"}>
              <Box p={1}>{singlePubData?.managerName}</Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Main;
