import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
};
const labelOptions = createSlice({
  name: "labelOptions",
  initialState,
  reducers: {
    updateLabelOptions: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { updateLabelOptions } = labelOptions.actions;
export default labelOptions.reducer;
