import * as React from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Drawer,
  AppBar,
  CssBaseline,
  Toolbar,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
  AccountCircle,
  Menu,
  MenuItem,
  useNavigate,
  SellIcon,
  LabelIcon,
  RuleOutlinedIcon,
  AddBusinessOutlinedIcon,
  DynamicFeedOutlinedIcon,
} from "../Collections/Imports";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import AltRouteOutlinedIcon from "@mui/icons-material/AltRouteOutlined";
import SwitchAccountOutlinedIcon from "@mui/icons-material/SwitchAccountOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import DatasetLinkedOutlinedIcon from "@mui/icons-material/DatasetLinkedOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import { Outlet } from "react-router-dom";
import "./style.css";
import useLogout from "../CustomHooks/useLogout";
import useIntrospect from "../CustomHooks/useIntrospect";
import { idpURL, logoutURL } from "../Config/envConfig";
import useRefreshToken from "../CustomHooks/useRefreshToken";
import { useEffect } from "react";
import LoginLogo from "../assets/images/login-logo.png";
import ZapLogo from "../assets/images/zappian-logo.png";
const drawerWidth = 200;
const ClippedDrawer = () => {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const introspectCall = useIntrospect();
  const refreshTokenCall = useRefreshToken();
  const [isActive, setIsActive] = React.useState(false);

  const location = useLocation();
  // const handlePopstate = () => {
  //   const currentPath = location.pathname;
  //   const activeIndex = allNav.findIndex((item) => item.to === currentPath);

  //   if (activeIndex !== -1) {
  //     setIsActive(true);
  //     setActiveMenuItem(currentPath);
  //   } else {
  //     setIsActive(false);
  //     setActiveMenuItem(allNav[0]?.to);
  //   }
  // };

  const dashboard = [
    {
      name: "Dashboard",
      route: "/dashboard",
    },
    {
      name: "All Reports",
      // route: "/all-reports",
    },
  ];
  const clientManagement = [
    {
      name: "Buyers",
      route: "/buyers",
    },
    {
      name: "Campaigns",
      route: "/campaigns",
    },
    {
      name: "Buyer Labels",
      route: "/buyer-labels",
    },
    {
      name: "Buyer Tags",
      route: "/buyer-tags",
    },
    {
      name: "Campaign Tags",
      route: "/campaign-tags",
    },
    {
      name: "PL / DNPL",
      route: "/pldnpl",
    },
  ];
  const publisherManagement = [
    {
      name: "Publishers",
      route: "/publisher",
    },

    {
      name: "Channels",
      route: "/channel",
    },
    {
      name: "Publisher Tags",
      // route: "/all-reports",
    },
    {
      name: "Channel Label",
      // route: "/all-reports",
    },
    {
      name: "Source - Channel Mapping",
      // route: "/all-reports",
    },
  ];
  const userManagement = [
    {
      name: "Users",
      // route: "/buyers",
    },
    {
      name: "Add User",
      // route: "/all-reports",
    },
  ];
  const distributionSetting = [
    {
      name: "Pingtree",
      // route: "/buyers",
    },
    {
      name: "Marketplace",
      // route: "/all-reports",
    },
    {
      name: "Silent",
      // route: "/all-reports",
    },
  ];
  const activeStyle = {
    width: "233px",
    height: "40px",
    margin: "0px 5px 5px 5px",
    background: "#2A4BC0",
    color: "white",
    gap: "4.17px",
    borderRadius: "5px",
    "&:hover": {
      background: "#4a72ff",
    },
  };
  const textActiveStyle = {
    color: "#fff !important",
    fontSize: "14px",
    "& .MuiListItemText-primary": {
      color: "#fff !important",
    },
  };
  // useEffect(() => {
  //   handlePopstate();
  //   window.addEventListener("popstate", handlePopstate);
  //   return () => {
  //     window.removeEventListener("popstate", handlePopstate);
  //   };
  // }, [location, allNav]);
  useEffect(() => {
    if (!document.hidden) {
      if (JSON.parse(localStorage.getItem("userCredential"))?.access_token) {
        introspectCall();
      } else {
        navigate("/");
      }
    }
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        if (JSON.parse(localStorage.getItem("userCredential"))?.access_token) {
          introspectCall();
        } else {
          navigate("/");
        }
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      const responce = await introspectCall();
      if (responce.data?.data?.active) {
        const id_token = JSON.parse(
          localStorage.getItem("userCredential")
        )?.id_token;
        window.location.href = `${idpURL}/connect/logout?post_logout_redirect_uri=${logoutURL}&id_token_hint=${id_token}`;
      } else {
        const res = await refreshTokenCall();
        if (res.data.data) {
          const id_token = JSON.parse(
            localStorage.getItem("userCredential")
          )?.id_token;
          window.location.href = `${idpURL}/connect/logout?post_logout_redirect_uri=${logoutURL}&id_token_hint=${id_token}`;
        }
      }
    } catch (error) {
      localStorage.clear();
      navigate("/");
    }
  };
  return (
    <>
      <Box sx={{ display: "flex" }} className="layout">
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            backgroundColor: "#0C247B",
            zIndex: "2",
            // zIndex: (theme) => theme.zIndex.drawer + 1,
            [`& .MuiToolbar-root`]: {
              minHeight: "50px",
            },
          }}
        >
          <Toolbar className="topToolbar">
            <Box component="div" sx={{ flexGrow: 1 }}>
              <img src={LoginLogo} alt="icon" />
              <img src={ZapLogo} alt="Zappian Logo" className="zappian-logo" />
            </Box>

            <div>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem
                  onClick={() => {
                    handleLogout();
                    handleClose();
                  }}
                >
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            zIndex: 1,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: "auto" }} className="sideNavBox">
            <List className="sideNav">
              {dashboard.map((text, index) => (
                <ListItem
                  key={text?.name}
                  disablePadding
                  onClick={() => navigate(text?.route)}
                >
                  <ListItemButton className="sideNavButton">
                    <ListItemIcon
                      className="sideNavIcon"
                      sx={{
                        minWidth: "35px",
                      }}
                    >
                      {index === 0 ? (
                        <AppsOutlinedIcon />
                      ) : index === 1 ? (
                        <AssessmentOutlinedIcon />
                      ) : (
                        ""
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={text.name}
                      className="sideMenuItem"
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <li style={{ listStyleType: "none" }}>
              <Typography
                sx={{ mt: 0.5, ml: 2 }}
                color="text.secondary"
                display="block"
                variant="caption"
              >
                Client Management
              </Typography>
            </li>
            <List className="sideNav">
              {clientManagement.map((text, index) => (
                <ListItem
                  key={text?.name}
                  disablePadding
                  className="sideMenuItem"
                  onClick={() => navigate(text?.route)}
                >
                  <ListItemButton
                    className="sideNavButton"
                    sx={location.pathname == text?.route ? activeStyle : ""}
                  >
                    <ListItemIcon
                      className="sideNavIcon"
                      sx={
                        location.pathname == text?.route
                          ? { filter: "invert(1)", minWidth: "35px" }
                          : { minWidth: "35px", filter: "invert(0)" }
                      }
                    >
                      {index === 0 ? (
                        <ListAltOutlinedIcon />
                      ) : index === 1 ? (
                        <CampaignOutlinedIcon />
                      ) : index === 2 ? (
                        <LabelIcon />
                      ) : index === 3 ? (
                        <SellIcon />
                      ) : index === 4 ? (
                        <SellIcon />
                      ) : index === 5 ? (
                        <RuleOutlinedIcon />
                      ) : index === 6 ? (
                        <AltRouteOutlinedIcon />
                      ) : (
                        ""
                      )}
                    </ListItemIcon>
                    <ListItemText
                      sx={
                        location.pathname == text?.route ? textActiveStyle : ""
                      }
                      primary={text?.name}
                      className="sideMenuItem"
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <li style={{ listStyleType: "none" }}>
              <Typography
                sx={{ mt: 0.5, ml: 2 }}
                color="text.secondary"
                display="block"
                variant="caption"
              >
                Publisher Management
              </Typography>
            </li>
            <List className="sideNav">
              {publisherManagement.map((text, index) => (
                <ListItem
                  key={text?.name}
                  disablePadding
                  className="sideMenuItem"
                  onClick={() => navigate(text?.route)}
                >
                  <ListItemButton
                    className="sideNavButton"
                    sx={location.pathname == text?.route ? activeStyle : ""}
                  >
                    <ListItemIcon
                      className="sideNavIcon"
                      sx={
                        location.pathname == text?.route
                          ? { filter: "invert(1)", minWidth: "35px" }
                          : { minWidth: "35px", filter: "invert(0)" }
                      }
                    >
                      {index === 0 ? (
                        <SwitchAccountOutlinedIcon />
                      ) : index === 1 ? (
                        <AccountTreeOutlinedIcon />
                      ) : index === 2 ? (
                        <SellIcon />
                      ) : index === 3 ? (
                        <LabelIcon />
                      ) : index === 4 ? (
                        <DatasetLinkedOutlinedIcon />
                      ) : (
                        ""
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={text?.name}
                      sx={
                        location.pathname == text?.route ? textActiveStyle : ""
                      }
                    />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <li style={{ listStyleType: "none" }}>
              <Typography
                sx={{ mt: 0.5, ml: 2 }}
                color="text.secondary"
                display="block"
                variant="caption"
              >
                Lead Distribution
              </Typography>
            </li>
            <List className="sideNav">
              {distributionSetting.map((text, index) => (
                <ListItem
                  key={text?.name}
                  disablePadding
                  className="sideMenuItem"
                  onClick={() => navigate(text?.route)}
                >
                  <ListItemButton className="sideNavButton">
                    <ListItemIcon
                      className="sideNavIcon"
                      sx={{
                        minWidth: "35px",
                      }}
                    >
                      {index === 0 ? (
                        <AltRouteOutlinedIcon />
                      ) : index === 1 ? (
                        <AddBusinessOutlinedIcon />
                      ) : (
                        <DynamicFeedOutlinedIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={text?.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <li style={{ listStyleType: "none" }}>
              <Typography
                sx={{ mt: 0.5, ml: 2 }}
                color="text.secondary"
                display="block"
                variant="caption"
              >
                User Management
              </Typography>
            </li>
            <List className="sideNav">
              {userManagement.map((text, index) => (
                <ListItem
                  key={text?.name}
                  disablePadding
                  className="sideMenuItem"
                  onClick={() => navigate(text?.route)}
                >
                  <ListItemButton className="sideNavButton">
                    <ListItemIcon
                      className="sideNavIcon"
                      sx={{
                        minWidth: "35px",
                      }}
                    >
                      {index === 0 ? (
                        <AdminPanelSettingsOutlinedIcon />
                      ) : index === 1 ? (
                        <PersonAddAltOutlinedIcon />
                      ) : (
                        ""
                      )}
                    </ListItemIcon>
                    <ListItemText primary={text?.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            pt: 1,
            pb: 1,
            background: "#F1F2F4",
          }}
        >
          {/* <Box>
            <Toolbar />
          </Box> */}

          <Box mt={8} ml={1} mr={1}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ClippedDrawer;
